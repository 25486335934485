//Detailed Explanation of useInvoiceReducer.jsx
/*
The use-invoice-reducer.jsx file provides a state management solution using the useReducer hook, which is part of React. 
This approach is ideal for managing complex state logic in React applications. 
Below, I’ll break down the code, explain its functionality, and justify why it was implemented in this way.
*/

/*
 The initialState object defines the initial state of the invoice generation process. 
 This includes all the necessary fields that the application needs to manage, 
 such as personal information, service selection, pricing details, 
 VAT configuration, and modal visibility.

 Why It's Important:
 Establishing an initial state is critical because it provides a clear, 
 organized structure for the state that will be managed throughout the lifecycle of the component. 
 This prevents errors by ensuring that each piece of state has a defined starting value.
*/
export const initialState = {
  //Holds user details such as name, email, address, etc.
  personalInfo: {
    name: "",
    email: "",
    address01: "",
    address02: "",
    street: "",
    eircode: "",
  },
  errrors: {},
  // Stores the data related to services available for selection
  serviceData: {},
  //Tracks the currently selected service
  selectedService: "",
  //Lists specific services related to the selected service
  specificServices: [],
  //Tracks specific services selected by the user for the invoice
  selectedSpecificServices: [],
  editingIndex: null,
  //pricing-related fields: totalBasePrice, vatAmount, and finalTotalPrice are used for price calculations.
  priceInput: "",
  irelandData: {},
  selectedCounty: "",
  selectedCity: "",
  cities: [],
  paymentInfoData: {},
  selectedPaymentMethod: "",
  date: "",
  expire: "",
  vatPercentage: 13.5,
  isVatEditable: false,
  totalBasePrice: 0,
  vatAmount: 0,
  finalTotalPrice: 0,
  showModal: false,
  editingIndex2: -1,
  invoice: null, // Single invoice object
  proposal: null, // Single proposal object
};

/*
  Purpose: The invoiceReducer function is the core of the state management system in this context. 
  It defines how the state should change in response to various actions. Each action type corresponds to a different state update.
  
  Why It's Important: The useReducer hook is chosen over useState because the application’s 
  state is complex and involves multiple interrelated pieces of data. 
  useReducer provides a more structured way to manage this complexity, 
  making the state transitions predictable and easier to debug.
  */
export const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_PERSONAL_INFO":
      return {
        ...state,
        personalInfo: { ...state.personalInfo, [action.field]: action.value },
      };
    case "SET_ERRORS":
      return {
        ...state,
        errors: action.errors,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: {},
      };
      case 'SET_FORM_DATA':
        return {
          ...state,
          ...action.data,
        };
    case "SET_SERVICE_DATA":
      return { ...state, serviceData: action.data };
    case "SET_SELECTED_SERVICE":
      return {
        ...state,
        selectedService: action.service,
        specificServices: state.serviceData[action.service] || [],
      };
    case "SET_SELECTED_SPECIFIC_SERVICES":
      return {
        ...state,
        selectedSpecificServices: action.services,
        editingIndex: null,
        priceInput: "",
      };
    case "SET_EDITING_INDEX":
      return { ...state, editingIndex: action.index };
    case "SET_PRICE_INPUT":
      return { ...state, priceInput: action.value };
    case "SET_IRELAND_DATA":
      return { ...state, irelandData: action.data };
    case "SET_SELECTED_COUNTY":
      return {
        ...state,
        selectedCounty: action.county,
        cities: state.irelandData[action.county] || [],
        selectedCity: "",
      };
    case "SET_SELECTED_DATE":
      return { ...state, date: action.date };
    case "SET_SELECTED_EXPIRE_DATE":
      return { ...state, expire: action.expire };
    case "SET_SELECTED_CITY":
      return { ...state, selectedCity: action.city };
    case "SET_PAYMENT_INFO_DATA":
      return { ...state, paymentInfoData: action.data };
    case "SET_SELECTED_PAYMENT_METHOD":
      return { ...state, selectedPaymentMethod: action.method };
    case "SET_VAT_PERCENTAGE":
      return { ...state, vatPercentage: action.value };
    case "TOGGLE_VAT_EDIT":
      return { ...state, isVatEditable: !state.isVatEditable };
    case "SET_PROPOSAL":
      console.log("Reducer setting proposal:", action.data);
      return {
        ...state,
        proposal: action.data,
      };
    case "SET_PROPOSAL_STATUS":
      console.log("Reducer updating proposal status to:", action.data); // Log to verify status update
      return {
        ...state,
        proposal: { ...state.proposal, status: action.data },
      };

    case "SET_TOTALS":
      return {
        ...state,
        totalBasePrice: action.totalBasePrice,
        vatAmount: action.vatAmount,
        finalTotalPrice: action.finalTotalPrice,
      };
    case "SET_INVOICE":
      return {
        ...state,
        invoice: action.data, // Set invoice in state
      };
    case "SET_INVOICE_STATUS":
      return {
        ...state,
        invoice: { ...state.invoice, status: action.data },
      };
    case "SET_FORM_TYPE":
      return {
        ...state,
        formType: action.formType, // Set the form type to either 'invoice' or 'proposal'
      };
    case "TOGGLE_MODAL":
      return { ...state, showModal: !state.showModal };
    // New case to clear all fields
    case "CLEAR_ALL_FIELDS":
      return {
        ...state,
        personalInfo: {
          name: "",
          email: "",
          address01: "",
          address02: "",
          street: "",
          eircode: "",
        },
        selectedService: "",
        specificServices: [],
        selectedSpecificServices: [],
        selectedCounty: "",
        selectedCity: "",
        priceInput: "",
        vatPercentage: state.vatPercentage, // Keep default or current VAT percentage
        totalBasePrice: 0,
        vatAmount: 0,
        finalTotalPrice: 0,
        selectedPaymentMethod: "",
        date: "",
        expire: "",
      };
    default:
      return state;
  }
};

//Purpose and Functionality
/*
  1. Why This Approach?
  Complex State Management: The invoice generation process involves numerous state variables that are interdependent. 
  Managing this complexity with individual useState hooks would be cumbersome and error-prone. 
  The useReducer pattern centralizes the state logic, making it easier to maintain and extend.
  
  Predictable State Transitions: The invoiceReducer function ensures that state transitions are explicit and predictable. 
  Each action type is clearly defined, and the corresponding state update is handled in a controlled manner. 
  This predictability is crucial in applications where the state drives the user interface.
  
  Scalability: As the application grows, new actions and state updates can be added to the reducer without disrupting existing functionality. 
  This makes it easier to scale the application while keeping the state management organized.
  
  Modern React Patterns: React’s useReducer combined with Context API is a modern approach that aligns 
  with best practices for managing complex state in large applications. 
  It leverages React's capabilities to provide a clean and efficient state management solution.
  */
