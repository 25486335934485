//I've refactored the "Selected Services Summary" block into a reusable React component. This approach enhances readability, maintainability, and reusability of your code.
import React, { useContext, useEffect, useRef  } from "react";
import { FormContext } from "./context/form-provider";
import { useFormHandlers } from "./hooks/form-handlers";
import DynamicFormField from "./dynamic-form-field"
import itemSummaryConfig from './json/item-summary-config.json'
import { safeValue } from './utils/item-summary'

// This component handles the display and management of the selected services summary
const ItemSummary = () =>{
  const { state } = useContext(FormContext);
  const { handlePriceChange, handleSavePrice, handleDeleteService, handleKeyPress } = useFormHandlers();
  const priceInputRef = useRef(null);


    // Safely access state properties using the utility function
    const selectedSpecificServices = state.selectedSpecificServices || [];
    const editingIndex = safeValue(state.editingIndex, -1); // Default to -1 if undefined
    const priceInput = safeValue(state.priceInput, '');
    const errors = state.errors || {};

    useEffect(() => {
      if (editingIndex !== -1 && priceInputRef.current) {
        priceInputRef.current.focus();
      }
    }, [editingIndex]);


    return (
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title mb-0">Selected Services Summary</h5>
          </div>
          <div className="card-body">
            <div className="mb-3" style={{ fontSize: "13px", padding: "5px" }}>
              {selectedSpecificServices.map((service, index) => (
                <div key={index} className="d-flex align-items-center mb-2">
                  <span className="me-2" style={{ padding: "12px" }}>
                    {service.specificService} for {service.service}
                  </span>
                  {editingIndex === index ? (
                    <>
                      {/* Render input field for price with ref and key press handler */}
                      <DynamicFormField
                        field={itemSummaryConfig.fields.find(field => field.name === "price")}
                        value={priceInput}
                        onChange={handlePriceChange}
                        onKeyDown={handleKeyPress}  // Use onKeyDown here
                        ref={priceInputRef}  // Ref to focus on input
                        errors={errors}
                      />
                      <button
                        type="button"
                        onClick={() => handleSavePrice(index)}
                        className="btn btn-success"
                        style={{
                          background: "transparent",
                          color: "black",
                          border: "none",
                          fontSize: "12px",
                        }}
                      >
                        Save
                      </button>
                    </>
                  ) : (
                    <span className="me-2">
                      {service.price
                        ? `Price (VAT included): €${service.price.toFixed(2)}`
                        : "No price set"}
                      {errors?.priceInput && (
                        <div className="text-danger">{errors.priceInput}</div>
                      )}
                    </span>
                  )}
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      color: "black",
                      border: "none",
                      fontSize: "12px",
                    }}
                    onClick={() => handleDeleteService(index)}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default ItemSummary;