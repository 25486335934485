// form-validation.js

import { validateSelectField, validateEmail, validateName } from "../../../validators/index";

// Function to validate form fields
export const validateForm = (state,  isEditMode = false) => {
  const newErrors = {
    name: validateName(state.personalInfo.name),
    email: validateEmail(state.personalInfo.email),
    address01: validateSelectField(state.personalInfo.address01, 'Address Line 1'),
    date: validateSelectField(state.date, "Date"),
    selectedCounty: validateSelectField(state.selectedCounty, "County"),
    selectedCity: validateSelectField(state.selectedCity, "City"),
    selectedService: validateSelectField(state.selectedService, "Service" ),
  };

  // In create mode, validate the selected service field
  // Skip service validation in edit mode entirely
  if (!isEditMode) {
    newErrors.selectedService = validateSelectField(state.selectedService, "Service");
  }

  // Filter out any empty error messages
  return Object.keys(newErrors).reduce((acc, key) => {
    if (newErrors[key]) acc[key] = newErrors[key];
    return acc;
  }, {});
};
