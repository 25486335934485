import React, { useContext, Suspense } from "react";
import { RegisterFormContext } from "../context/register-form";
import { useRegisterHandlers } from "../hooks/register-form-handlers";
import RegisterDynamicFormFields from "../register-dynamic-form-fields";

import { handleRegister } from "../create-user";
import { loadFormConfigs } from "../utils/load-form-config";
import AlertMessage from "../../utils/alerts/alert-message";
import { useUserFormConfig } from "../../utils/form-config/use-form-config";
import { useAlert } from "../../utils/alerts/use-alert";

const RegisterEmployee = () => {
  const { state, dispatch } = useContext(RegisterFormContext);
  const { handleInputChange, handleFileChange } = useRegisterHandlers();
  const formConfig = useUserFormConfig(); // Load form configuration
  const {
    showAlert,
    alertType,
    alertMessage,
    showSuccessAlert,
    showErrorAlert,
    closeAlert,
  } = useAlert(); // Manage alerts

  const handleCreateUser = (e) => {
    e.preventDefault();
    handleRegister(
      "user",
      state,
      dispatch,
      () => showSuccessAlert("User register successfully!"),
      () =>
        showErrorAlert(
          "There was an issue saving the User information: Please try again"
        )
    );
  };

  if (!formConfig) {
    return <div>Loading form configuration...</div>;
  }

  return (

    <div id="app-layout">
      <div className="content-page">
        <div className="content">
          <div className="container-xxl">
            <div className="row">
              {/* Check if showAlert is true to display the AlertMessage component */}
              {showAlert && (
                <div className="col-xl-15">
                  <AlertMessage
                    type={alertType}
                    message={alertMessage}
                    showAlert={showAlert}
                    onClose={closeAlert}
                  />
                </div>
              )}
              <div className="col-xl-15">
                <div className="py-3 d-flex align-items-sm-center flex-sm-row flex-column">
                  <div className="flex-grow-1">
                    <h4 className="fs-18 fw-semibold m-0">Register User</h4>
                  </div>
                  <div className="text-end">
                    <ol className="breadcrumb m-0 py-0">
                      <li className="breadcrumb-item">
                        <a href="#">Forms</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Form Validation
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">Information</h5>
                  </div>
                  <div className="card-body">
                    {/* Form for dynamic fields */}
                    <form className="row g-3" onSubmit={handleCreateUser}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <RegisterDynamicFormFields
                          formConfig={formConfig}
                          formValues={state.formValues}
                          handleInputChange={handleInputChange}
                          errors={state.errors}
                        />
                      </Suspense>
                      <div className="d-print-none">
                        <div className="float-end">
                          <button className="btn btn-primary" type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  );
};

export default RegisterEmployee;
