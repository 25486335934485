import React from "react";
import { Link } from "react-router-dom";

const DocumentNav = ({ documentType }) => {
  const path =
    documentType === "invoice" ? "/admin/invoices" : "/admin/proposals";
  return (
    <>
      <div className="py-3 d-flex align-items-sm-center flex-sm-row flex-column">
        <div className="flex-grow-1">
          <h4 className="fs-18 fw-semibold m-0">
            {documentType === "invoice" ? "Invoice" : "Proposal"}
          </h4>
        </div>
        <div className="text-end">
          <ol className="breadcrumb m-0 py-0">
            <li className="breadcrumb-item">
              <Link to={path}>
                <button className="btn btn-primary">
                  {documentType === "invoice" ? "Invoices" : "Proposals"}
                </button>
              </Link>
            </li>
            <li className="breadcrumb-item active">
              {documentType === "invoice" ? "Invoice" : "Proposal"}
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default DocumentNav;
