import React, { useContext } from "react";
import { FormContext } from "./context/form-provider";
import DynamicFormField from "./dynamic-form-field";
import vatFormConfig from "././json/vat-form-config.json";
import { useFormHandlers } from "./hooks/form-handlers";
import { safeNumber } from "./utils/safe-numbers";

// Component to render the summary and VAT details
const Summary = () => {
  const { state } = useContext(FormContext);
  const { toggleVatEdit, handleVatChange } = useFormHandlers();
    
  // Safely handle the potential for undefined values using the safeNumber utility
  const totalBasePrice = safeNumber(state.totalBasePrice);
  const vatAmount = safeNumber(state.vatAmount);
  const finalTotalPrice = safeNumber(state.finalTotalPrice);
  const vatPercentage = safeNumber(state.vatPercentage); 


    return (
      <div className="col-lg-6 mt-3">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h5 className="card-title mb-0">Summary</h5>
            <button className="btn btn-primary" onClick={toggleVatEdit}>
              {state.isVatEditable ? 'Save' : 'Edit'}
            </button>
          </div>
          <div className="card-body">
            <div className="mb-3">
              <h6>Total Base Price (Before VAT): €{totalBasePrice.toFixed(2)}</h6>
              <h6>VAT {vatPercentage.toFixed(1)}% : €{vatAmount.toFixed(2)}</h6>
              <h6>Final Total Price (After VAT): €{finalTotalPrice.toFixed(2)}</h6>
            </div>
            {state.isVatEditable && (
              // Render VAT input field dynamically
              vatFormConfig.fields.map((field) => (
                <DynamicFormField
                  key={field.name}
                  field={field}
                  value={state[field.name]}
                  onChange={handleVatChange}
                  errors={state.errors}
                />
              ))
            )}
          </div>
        </div>
      </div>
    );
  };
  
  export default Summary;
