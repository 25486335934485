// NotificationDropdown.js
import React from 'react';

const notifications = [
  {
    id: 1,
    img: "assets/images/users/user-12.jpg",
    name: "Carl Steadham",
    message: "Completed Improve workflow in Figma",
    time: "5 min ago"
  },
  {
    id: 2,
    img: "assets/images/users/user-2.jpg",
    name: "Olivia McGuire",
    message: "Added file to Create dark mode for our iOS",
    time: "1 min ago"
  },
  // More notifications...
];

const NotificationDropdown = () => {
  return (
    <li className="dropdown notification-list topbar-dropdown">
      <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#">
        <i data-feather="bell" className="noti-icon"></i>
        <span className="badge bg-danger rounded-circle noti-icon-badge">9</span>
      </a>
      <div className="dropdown-menu dropdown-menu-end dropdown-lg">
        <div className="dropdown-item noti-title">
          <h5 className="m-0">
            <span className="float-end">
              <a href="#" className="text-dark">
                <small>Clear All</small>
              </a>
            </span>
            Notification
          </h5>
        </div>
        <div className="noti-scroll">
          {notifications.map((notification) => (
            <a
              key={notification.id}
              href="javascript:void(0);"
              className="dropdown-item notify-item text-muted link-primary"
            >
              <div className="notify-icon">
                <img
                  src={notification.img}
                  className="img-fluid rounded-circle"
                  alt=""
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <p className="notify-details">{notification.name}</p>
                <small className="text-muted">{notification.time}</small>
              </div>
              <p className="mb-0 user-msg">
                <small className="fs-14">{notification.message}</small>
              </p>
            </a>
          ))}
        </div>
        <a href="javascript:void(0);" className="dropdown-item text-center text-primary notify-item notify-all">
          View all
          <i className="fe-arrow-right"></i>
        </a>
      </div>
    </li>
  );
};

export default NotificationDropdown;