import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlayCircle,
  faPauseCircle,
  faQuoteLeft,
  faQuoteRight,
  faVolumeUp,
  faVolumeMute,
} from "@fortawesome/free-solid-svg-icons";
import "./about-us.styles.css";

const AboutUs = () => {
  const videoRef = useRef(null); // Referece to the video element
  const [isPlaying, setIsPlaying] = useState(false); // State to manage video play/pause
  const [isMuted, setIsMuted] = useState(false); // State to manage video mute/unmute

  // Function to play/pause the video
  const togglePlayPause = () => {
    const videoElement = videoRef.current;
    if (videoElement.paused) {
      videoElement.play(); // Start playing the video if it is paused
      setIsPlaying(true); // Update the state to indicate that the video is playing
    } else {
      videoElement.pause(); // Pause the video if it is playing
      setIsPlaying(false); // Update the state to indicate that the video is paused
    }
  };

  // Function to mute/unmute the video
  const toggleMute = () => {
    const videoElement = videoRef.current;
    videoElement.muted = !videoElement.muted; // Toggle the muted state of the video
    setIsMuted(videoElement.muted); // Update the state to indicate whether the video is muted or not
  };

  useEffect(() => {
    const videoElement = videoRef.current; // Copy the video element reference to a variable
    // Add event listener to the video element to update the play/pause state
    const handleVideoEnd = () => setIsPlaying(false);
    if (videoElement) {
      videoElement.addEventListener("ended", handleVideoEnd);
    }

    // cleanup function runs
    return () => {
      if (videoElement) {
        videoElement.removeEventListener("ended", handleVideoEnd);
      }
    };
  }, []);

  return (
    <div className="about-us-container" id="about-us">
      <h1 className="about-us-heading">About Us</h1>
      <div className="about-us-heading-line"></div>
      <div className="video-wrapper">
        <video
          ref={videoRef}
          src="https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/video/Reels+Magic.mp4"
          className="video"
          poster="https://magicrainbow.s3.eu-west-1.amazonaws.com/gallery/catalog/Wood/image3.jpg" // Replace with your poster image URL
          preload="metadata" // Preload video metadata
          playsInline // Ensures the video plays inline on mobile devices
          controls={false} // Hide default controls
        />
        <div className="controls">
          <div className="video-bar-wrapper">
            <div className="video-bar"></div>
          </div>
          <div className="buttons">
            <button id="play-pause" onClick={togglePlayPause}>
              <FontAwesomeIcon
                icon={isPlaying ? faPauseCircle : faPlayCircle}
                className="fa"
              />
            </button>
            <button id="mute-unmute" onClick={toggleMute}>
              <FontAwesomeIcon
                icon={isMuted ? faVolumeMute : faVolumeUp}
                className="fa"
              />
            </button>
          </div>
        </div>
      </div>
      <p className="about-us-paragraph">
        <FontAwesomeIcon icon={faQuoteLeft} className="fa-quote-left" />
        Maciej and his team specialise in all aspects of interior and exterior
        painting and decorating. Clients include home owners, schools,
        historical buildings and more. We use only the very best materials and
        techniques in order to get the best finish possible. On-time completion
        guaranteed with a 13 year track record of success.
        <FontAwesomeIcon icon={faQuoteRight} className="fa-quote-right" />
      </p>
    </div>
  );
};

export default AboutUs;
