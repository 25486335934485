import React, { useEffect, useState } from "react";
import {
  useInvoiceTableContext,
  useProposalTableContext,
  useCustomerTableContext,
  useLeadTableContext, // Add context for leads
  useEmployeeTableContext,
  useUserTableContext,
} from "../context/table-context";
import { useTableData } from "../hooks/use-table-data";
import SearchBar from "./serach-bar";
import tableConfig from "./json/table-config.json";
import axios from "axios";
import Pagination from "../utils/pagination";
import { renderCell } from "../utils/table";

const Table = ({ tableType }) => {
  // Add Customer and Lead contexts
  const invoiceContext = useInvoiceTableContext();
  const proposalContext = useProposalTableContext();
  const customerContext = useCustomerTableContext();
  const leadContext = useLeadTableContext();
  const employeeContext = useEmployeeTableContext();
  const userContext = useUserTableContext();

  const { handleDeleteClick } = useTableData(tableType); 

  // Dynamically choose the context based on tableType
  const context =
    tableType === "invoices"
      ? invoiceContext
      : tableType === "proposals"
      ? proposalContext
      : tableType === "employees"
      ? employeeContext
      : tableType === "users"
      ? userContext
      : tableType === "customers"
      ? customerContext
      : leadContext; // Add support for Customers and Leads

  // Fetch data when the table loads
  useTableData(tableType);

  const { state, dispatch } = context;
  const config = tableConfig[tableType];

  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 15;

  useEffect(() => {
    if (
      Array.isArray(state.filteredData[tableType]) &&
      state.filteredData[tableType].length > 0
    ) {
      setIsSearchVisible(true);
    }
  }, [state.filteredData, tableType]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const paginatedData = () => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return state.filteredData[tableType].slice(start, end);
  };

  const handleActionClick = async (item, newStatus) => {
    const apiUrl =
      tableType === "invoices"
        ? `${process.env.REACT_APP_API_URL}/api/admin/invoices/${item._id}`
        : `${process.env.REACT_APP_API_URL}/api/admin/proposals/${item._id}`;

    try {
      // Make the PUT request to update the status
      await axios.put(apiUrl, { status: newStatus });

      // Update the status in the local state after a successful request
      const updatedData = state.filteredData[tableType].map((dataItem) =>
        dataItem._id === item._id
          ? { ...dataItem, status: newStatus }
          : dataItem
      );

      // Dispatch the updated data to the filteredData
      dispatch({ type: "SET_FILTERED_DATA", tableType, payload: updatedData });

      console.log(
        `${tableType.slice(0, -1)} with ID ${
          item._id
        } status updated to ${newStatus}`
      );
    } catch (error) {
      console.error(`Error updating ${tableType.slice(0, -1)} status:`, error);
    }
  };


  const totalPages = Math.ceil(
    state.filteredData[tableType].length / rowsPerPage
  );

  const tableNames = {
    invoices: "Invoices",
    proposals: "Business Proposals",
    users: "Users Details",
    employees: "Employees",
    customers: "Customers",
    leads: "Leads",
  };
  
  // Get the name for the current table type, with a fallback to "Data"
  const tableName = tableNames[tableType] || "Data";

  return (
    <div id="app-layout">
      <div className="content-page">
        <div className="content">
          <div className="container-xxl">
            <div className="py-3 d-flex align-items-sm-center flex-sm-row flex-column">
              <div className="flex-grow-1">
                <h4 className="fs-18 fw-semibold m-0">
                  {tableName}
                </h4>
              </div>
              <div className="text-end">
                <ol className="breadcrumb m-0 py-0">
                  <li className="breadcrumb-item">
                    <button href="#">
                    {tableName}
                    </button>
                  </li>
                  <li className="breadcrumb-item active">
                  {tableName}
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="card-body">
                <div className="table-responsive">
                  {isSearchVisible && (
                    <SearchBar
                      state={state}
                      dispatch={dispatch}
                      tableType={tableType}
                    />
                  )}

                  <table className="table mb-0">
                    <thead>
                      <tr>
                        {config.columns.map((column) => (
                          <th key={column.key}>{column.header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(state.filteredData[tableType]) ? (
                        paginatedData().map((item, index) => (
                          <tr key={item._id || index}>
                            {config.columns.map((column) => (
                              <td key={column.key}>
                                {renderCell(
                                  item,
                                  column,
                                  handleActionClick,
                                  handleDeleteClick,
                                  tableType
                                )}
                              </td>
                            ))}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={config.columns.length}>
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
