import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlayCircle,
  faPauseCircle,
  faVolumeUp,
  faVolumeMute,
} from "@fortawesome/free-solid-svg-icons";
import "./vertical-print.styles.css";

const VerticalPrint = () => {
  const videoRef = useRef(null); // Referece to the video element
  const [isPlaying, setIsPlaying] = useState(false); // State to manage video play/pause
  const [isMuted, setIsMuted] = useState(false); // State to manage video mute/unmute

  // Function to play/pause the video
  const togglePlayPause = () => {
    const videoElement = videoRef.current;
    if (videoElement.paused) {
      videoElement.play(); // Start playing the video if it is paused
      setIsPlaying(true); // Update the state to indicate that the video is playing
    } else {
      videoElement.pause(); // Pause the video if it is playing
      setIsPlaying(false); // Update the state to indicate that the video is paused
    }
  };

  // Function to mute/unmute the video
  const toggleMute = () => {
    const videoElement = videoRef.current;
    videoElement.muted = !videoElement.muted; // Toggle the muted state of the video
    setIsMuted(videoElement.muted); // Update the state to indicate whether the video is muted or not
  };

  useEffect(() => {
    const videoElement = videoRef.current; // Copy the video element reference to a variable
    // Add event listener to the video element to update the play/pause state
    const handleVideoEnd = () => setIsPlaying(false);
    if (videoElement) {
      videoElement.addEventListener("ended", handleVideoEnd);
    }

    // cleanup function runs
    return () => {
      if (videoElement) {
        videoElement.removeEventListener("ended", handleVideoEnd);
      }
    };
  }, []);

  return (
    <div className="section-vertical-print" id="about-us-vpt">
      <h1 className="section-heading-vertical-print">Exciting news!</h1>
      <div className="section-heading-line-vpt"></div>
      <div className="video-wrapper">
        <video
          ref={videoRef}
          src="https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/video/Mona-Lisa-vps.mp4"
          className="video-vpt"
          poster="https://artwallireland.s3.eu-west-1.amazonaws.com/email-marketing/retail/images-retail/lamounier_91432_a_photo_of_an_illustration_art_printed_on_a_ret_a3be3068-8ab4-4fb0-b1d1-26832949ba4a.png" // Replace with your poster image URL
          preload="metadata" // Preload video metadata
          playsInline // Ensures the video plays inline on mobile devices
          controls={false} // Hide default controls
        />
        <div className="controls">
          <div className="video-bar-wrapper">
            <div className="video-bar-vpt"></div>
          </div>
          <div className="buttons">
            <button id="play-pause" onClick={togglePlayPause}>
              <FontAwesomeIcon
                icon={isPlaying ? faPauseCircle : faPlayCircle}
                className="fa"
              />
            </button>
            <button id="mute-unmute" onClick={toggleMute}>
              <FontAwesomeIcon
                icon={isMuted ? faVolumeMute : faVolumeUp}
                className="fa"
              />
            </button>
          </div>
        </div>
      </div>
      <p className="section-vpt-paragraph">
        We're thrilled to expand our offerings beyond traditional painting and
        decorating services to now include cutting-edge vertical printing
        solutions. We're always on the forefront of innovation, and our new
        vertical printing service is no exception. Whether you're looking to add
        a bold statement piece to your home, create a captivating mural in your
        office space, or enhance the ambiance of your business with custom
        branding, our vertical printing service offers endless possibilities.
        <span>
          For more information check{" "}
          <a href="https://artwallireland.ie/">ArtWall Vertical Printing</a>
        </span>
      </p>
    </div>
  );
};

export default VerticalPrint;
