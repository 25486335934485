import React from "react";
import Flatpickr from "react-flatpickr";

const DateRangePicker = ({ label, dateRange, onChange }) => {
  return (
    <div className="p-3 border rounded bg-light">
      <h8>{label}</h8>
      <Flatpickr
        options={{ mode: "range" }}
        value={dateRange}
        onChange={onChange}
        className="form-control"
        placeholder="Select date range"
      />
    </div>
  );
};

export default DateRangePicker;