import React, { useContext, useEffect, Suspense } from "react";
import { FormContext } from "../context/form-provider";
import InvoiceForm from "./invoice-form";
import Summary from "../summary";
import Modal from "../modal";
import { useFormHandlers } from "../hooks/form-handlers";
import { fetchData } from "../fetch-data";
import { handleCreateDocument } from "../utils/create-document";
import Payment from "../payment";
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../../axios-config';
import { useUser } from "../../../auth/login.component/context/login-context";

// Helper function to format date to 'yyyy-MM-dd'
const formatDateForInput = (isoDate) => {
  if (!isoDate) return "";
  return new Date(isoDate).toISOString().split("T")[0];
};


const GenerateInvoice = () => {
  const { state, dispatch } = useContext(FormContext);
  const { updateStatus, updateStatusEdit} = useFormHandlers();
  const {user } = useUser()
  const navigate = useNavigate(); // Add useNavigate
  const { invoiceId } = useParams(); // Get invoiceId from URL
  const isEditMode = Boolean(invoiceId); // Determine if we're editing
  console.log('User in GenerateInvoice:', user);

  // Fetch data on mount
  useEffect(() => {
    const loadData = async () => {
      await fetchData("/serviceData.json", dispatch, "SET_SERVICE_DATA");
      await fetchData("/irelandData.json", dispatch, "SET_IRELAND_DATA");

      if (isEditMode) {
        // Fetch existing invoice data
        try {
          const response = await axios.get(
            `/api/admin/invoice/${invoiceId}`
          );
          const invoiceData = response.data;

          // Map the fetched data to the state structure
          dispatch({
            type: 'SET_FORM_DATA',
            data: {
              personalInfo: {
                name: invoiceData.customer.name || '',
                email: invoiceData.customer.email || '',
                address01: invoiceData.customer.address01 || '',
                address02: invoiceData.customer.address02 || '',
                street: invoiceData.customer.street || '',
                eircode: invoiceData.customer.eircode || '',
              },
              date: formatDateForInput(invoiceData.date) || "",
              selectedCounty: invoiceData.selectedCounty || '',
              selectedCity: invoiceData.selectedCity || '',
              selectedPaymentMethod: invoiceData.selectedPaymentMethod || '',
              selectedSpecificServices: invoiceData.selectedSpecificServices || [],
              vatPercentage: invoiceData.vatPercentage || 0,
              totalBasePrice: invoiceData.totalBasePrice || 0,
              vatAmount: invoiceData.vatAmount || 0,
              finalTotalPrice: invoiceData.finalTotalPrice || 0,
              status: invoiceData.status || '',
              // Add any other fields as necessary
            },
          });
        } catch (error) {
          console.error("Error fetching invoice data:", error);
        }
      }
    };

    loadData();
  }, [dispatch, isEditMode, invoiceId]);


  const handleCreateInvoice = () => {
    // Dispatch the formType as 'invoice'
    dispatch({
      type: "SET_FORM_TYPE",
      formType: "invoice",
    });

    if (isEditMode) {
      // If in edit mode, do not show modal, instead update and navigate
      handleCreateDocument(
        "invoice",
        state,
        dispatch,
        () => {
          alert("Invoice updated successfully!");
          // Navigate to the invoice document view
          navigate(`/admin/invoice/${invoiceId}`);
        },
        (error) => {
          alert("There was an issue updating the invoice. Please try again");
        },
        updateStatusEdit,
        invoiceId // Pass the invoiceId if updating
      );
    } else {
      // If creating a new invoice, show the modal
      dispatch({
        type: "SHOW_MODAL",
        showModal: true,
        modalMessage: "Would you like to print or email the invoice?",
      });

      handleCreateDocument(
        "invoice",
        state,
        dispatch,
        () => {
          alert("Invoice created successfully!");
        },
        (error) => {
          alert("There was an issue saving the invoice. Please try again");
        },
        updateStatus
      );
    }
  };

  return (
    <div id="app-layout">
      <div className="content-page">
        <div className="content">
          <div className="container-xxl">
            <div className="py-3 d-flex align-items-sm-center flex-sm-row flex-column">
              <div className="flex-grow-1">
              <h4 className="fs-18 fw-semibold m-0">
                  {isEditMode ? 'Edit Invoice' : 'Generate Invoice'}
                </h4>
              </div>
              <div className="text-end">
                <ol className="breadcrumb m-0 py-0">
                  <li className="breadcrumb-item">
                    <button href="#">Pages</button>
                  </li>
                  <li className="breadcrumb-item active">Invoice</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <Suspense fallback={<div>Loading...</div>}>
                <InvoiceForm
                  personalInfo={state.personalInfo}
                  date={state.date}
                  selectedCounty={state.selectedCounty}
                  selectedCity={state.selectedCity}
                  priceInput={state.priceInput}
                  specificServices={state.specificServices}
                  errors={state.errors}
                  state={state}
                />
                <Summary />
                <Payment />
              </Suspense>
              <div className="d-print-none">
                <div className="float-end">
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleCreateInvoice}
                  >
                    {isEditMode ? 'Update' : 'Submit'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Conditionally render Modal only when not in edit mode */}
      {!isEditMode && (
        <Modal
          showModal={state.showModal}
          message={state.modalMessage} // Pass the message dynamically
          formType={state.formType}
        />
      )}
    </div>
  );
};

export default GenerateInvoice;
