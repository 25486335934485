import React, { useState } from "react";
import axios from "axios";
import "./contact-form.styles.css";

const ContactForm = () => {
  // State to manage form data
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  // State to manage which input field is focused
  const [touchedField, setTouchedField] = useState({
    name: false,
    phone: false,
    email: false,
    message: false,
  });

  // State for managing error messages for each field
  const [errorText, setErrorText] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    result: "", // Added result field for general errors
  });

  // State to manage result message
  const [resultText, setResultText] = useState("");

  // Function to validate each field
  const validateField = (name, value) => {
    let errorMessage = "";

    switch (name) {
      case "name":
        if (!value) {
          errorMessage = "Name is required.";
        } else if (!/^[a-zA-Z\s]+$/.test(value)) {
          errorMessage = "Name should contain only letters.";
        }
        break;

      case "phone":
        if (!value) {
          errorMessage = "Phone number is required.";
        } else if (!/^\d{10}$/.test(value)) {
          errorMessage = "Phone number must be 10 digits.";
        }
        break;

      case "email":
        if (!value) {
          errorMessage = "Email is required.";
        } else if (
          !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
        ) {
          errorMessage = "Please enter a valid email address.";
        }
        break;

      case "message":
        if (!value) {
          errorMessage = "Message is required.";
        } else if (value.length < 10) {
          errorMessage = "Message must be at least 10 characters long.";
        }
        break;

      default:
        break;
    }

    return errorMessage;
  };

  // Handle input field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Validate field on change
    setErrorText({
      ...errorText,
      [name]: validateField(name, value),
    });
  };

  // Handle input field focus
  const handleFocus = (e) => {
    const { name } = e.target;
    setTouchedField({
      ...touchedField,
      [name]: true,
    });
  };

  // Handle input field blur
  const handleBlur = (e) => {
    const { name } = e.target;
    // If the field is empty, set it as not touched
    if (!formData[name]) {
      setTouchedField({
        ...touchedField,
        [name]: false,
      });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    const { name, phone, email, message } = formData;

    // Validate form data
    const nameError = validateField("name", name);
    const phoneError = validateField("phone", phone);
    const emailError = validateField("email", email);
    const messageError = validateField("message", message);

    if (nameError || phoneError || emailError || messageError) {
      setErrorText({
        name: nameError,
        phone: phoneError,
        email: emailError,
        message: messageError,
        result: "", // Clear result error on validation failure
      });
      setResultText("");
      return;
    }

    try {
      const response = await axios.post(
         `${process.env.REACT_APP_API_URL}/api/contact`,
        formData
      );
      setResultText(response.data.message || "Form submitted successfully!");
      setErrorText({
        name: "",
        phone: "",
        email: "",
        message: "",
        result: "", // Clear any result error after success
      });

      setFormData({
        name: "",
        phone: "",
        email: "",
        message: "",
      });

      setTouchedField({
        name: false,
        phone: false,
        email: false,
        message: false,
      });
    } catch (error) {
      console.error("Error submitting contact form:", error);
      setErrorText({
        ...errorText,
        result: "Failed to submit contact form",
      });
      setResultText("");
    }
  };

  return (
    <div id="section_contact" className="contact">
      <div className="contact-wrapper">
        <div className="contact-left"></div>
        <div className="contact-right">
          <h1 className="contact-heading">Request a Quote</h1>

          <form id="form" onSubmit={handleSubmit}>
            <div className="input-group">
              <input
                type="text"
                className="field"
                id="name-input"
                name="name"
                value={formData.name}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required
              />
              {/* Show label only if the field is not touched */}
              {!touchedField.name && (
                <label className="input-label" htmlFor="name-input">
                  Full Name
                </label>
              )}
              {errorText.name && <p className="error-text">{errorText.name}</p>}
            </div>
            <div className="input-group">
              <input
                type="tel"
                className="field"
                id="phone-input"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required
              />
              {/* Show label only if the field is not touched */}
              {!touchedField.phone && (
                <label className="input-label" htmlFor="phone-input">
                  Phone
                </label>
              )}
              {errorText.phone && (
                <p className="error-text">{errorText.phone}</p>
              )}
            </div>
            <div className="input-group">
              <input
                type="email"
                className="field"
                id="email-input"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required
              />
              {/* Show label only if the field is not touched */}
              {!touchedField.email && (
                <label className="input-label" htmlFor="email-input">
                  Email
                </label>
              )}
              {errorText.email && (
                <p className="error-text">{errorText.email}</p>
              )}
            </div>
            <div className="input-group">
              <textarea
                className="field"
                id="description-input"
                name="message"
                value={formData.message}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required
              ></textarea>
              {!touchedField.message && (
                <label className="input-label" htmlFor="description-input">
                  Message
                </label>
              )}
              {errorText.message && (
                <p className="error-text">{errorText.message}</p>
              )}
            </div>
            <button type="submit" className="submit-btn">
              Submit
            </button>
            {/* Display general error message */}
            {errorText.result && <p className="error-text">{errorText.result}</p>}
            {/* Display result message */}
            {resultText && (
              <p className="message-box result-text">{resultText}</p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
