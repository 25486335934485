// src/components/Document.jsx

import React, {useEffect }from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import useFetchDocument from '../utils/use-fetch-document'
import documentConfig from '../utils/json/doc-config.json'; // JSON config for dynamic fields
import DocumentHeader from './document-header'
import DocumentTable from './document-table'
import DocumentSummary from './document-summary'
import DocumentFooter from './document-footer';
import DocumentNav from './document-nav';



const Document = () => {
  const { documentType, documentId } = useParams(); // Extract params from URL
  const { documentData, loading } = useFetchDocument(documentType, documentId);
  const navigate = useNavigate();


  useEffect(() => {
    if (documentData) {
      console.log("Document Data:", documentData);
      console.log("Customer Data:", documentData.customer);
    }
  }, [documentData]);

  // Render loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!documentData) {
    return <div>No data available</div>;
  }

  // Get fields and itemsKey from JSON config based on document type
  const { fields, itemsKey } = documentConfig[documentType] || {};



  return (
    <div id="app-layout">
      <div className="content-page">
        <div className="content">
          <div className="container-xxl">
            <DocumentNav documentType={documentType} />
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="panel-body">
                    <DocumentHeader documentType={documentType} documentData={documentData} />
                      {/* Document Table */}
                      <DocumentTable
                        fields={fields}
                        itemsKey={itemsKey}
                        documentData={documentData}
                      />

                      {/* Document Summary */}
                      <DocumentSummary documentData={documentData} />

                      {/* Document Footer */}
                      <DocumentFooter documentType={documentType} documentData={documentData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> {/* container-fluid */}
            {/* Invoice Content End */}

            {/* Buttons Outside Invoice Content */}
            <div className="d-print-none mt-4">
              <div className="float-end">
                <button
                  className="btn btn-dark border-0 me-2"
                  onClick={() => window.print()}
                >
                  <i className="mdi mdi-printer me-1"></i>Print
                </button>
                <button className="btn btn-primary me-2">Email</button>
                <button className="btn btn-primary" onClick={() =>  navigate(`/admin/generate-${documentType}/${documentId}`)
                }>Edit</button>
              </div>
              <div className="clearfix"></div>
            </div>
        </div> {/* content */}
      </div> {/* content-page */}
    </div>
  );
};

export default Document;