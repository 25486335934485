import React from "react";
import { useEffect, useState} from "react";
import axios from "axios";
import ServicesDescription from "../../components/service-description.component/service-descripition";
import AboutUs from "../../components/about-us.component/about-us";
import Testimonial from "../../components/testimonial.component/testimonial";
import KeyServiceDescription from "../../components/key-services-description.component/key-service-description";
import Galleries from "../../components/galeries.component/galleries";
import OurCustomers from "../../components/our-customers.component/our-customers";
import VerticalPrint from "../../components/vertical-print.component/vertical-print.component";
import ContactForm from "../../components/contact-form.component/contact-form";


import "./home.styles.css";


/*The Home component fetches image URLs from a JSON file when it mounts.
It displays the images in a slider, changing the displayed image every 5 seconds.
*/
const Home = () => {
  const [images, setImages] = useState([]); //Initializes the images state as an empty array. This state will hold the list of image URLs fetched from the JSON file.
  const [currentImage, setCurrentImage] = useState(0); //This state keeps track of the index of the currently displayed image.
 

  //This hook runs after the component mounts. It is used here to fetch the image data from hero-img.json.
  useEffect(() => {
    axios.get('/hero-img.json')
      .then((res) => {
        setImages(res.data);
      })
      .catch((err) => {
        console.error("There was an error fetching the images!", err);
      });
  }, []);

//This hook sets up a timer to change the current image every 5 seconds.
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage(prevIndex => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [images]);
//This provides feedback to the user while the images are being fetched.
  if (!images.length) {
    return <div>Loading...</div>;
  }

  return (
    <div className="home">
    <header className="header">
    <div className="slider">
      {images.map((image, index) => (
        <div 
        key={index} 
        className={`slider-image-wrapper ${index === currentImage ? "show" : ""}`}
      >
          <img
            src={image.url}
            alt={`slide-${index}`}
            className="slider-image"
          />
        </div>
      ))}
    </div>
    <div className="logo-image">
      <img src="https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/logo/Logo.png" alt="logo" className="logo" />
    </div>
    <div className="banner">
      <h1 className="title"><span>Welcome to</span>Magic Rainbow Painting & Decorating</h1>
      
      <p className="subtitle">Dublin based business, specialise in all aspects of interior and
      exterior painting and decorating.</p>
      <a href="#contact" className="cta">Request a quote</a>
    </div>
    </header>
    <section id="services">
        <ServicesDescription />
      </section>
      <section id="about">
        <AboutUs />
      </section>
      <section id="testimonials">
        <Testimonial />
      </section>
      <section id="key-services">
        <KeyServiceDescription />
      </section>
      <section id="galleries">
        <Galleries />
      </section>
      <section id="our-customers">
        <OurCustomers />
      </section>
      <section id="vertical-print">
        <VerticalPrint />
      </section>
      <section id="contact">
        <ContactForm />
      </section>
    </div>
  );
}

export default Home;