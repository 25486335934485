import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axiosInstance from '../../axios-config';
import {jwtDecode} from 'jwt-decode';
import { useUser } from '../../auth/login.component/context/login-context'; // Assuming you have a UserContext for managing user state

const PrivateRoute = ({ requiredRoles = [] }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [hasRole, setHasRole] = useState(false);
  const { user, setUser, logout } = useUser(); // Use the user context for globally managing authentication

  // Helper function to check if the token is expired
  const isTokenExpired = (token) => {
    if (!token) return true;
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decoded.exp < currentTime;
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        console.log('Checking authentication...');
        if (user?.token && !isTokenExpired(user.token)) {
          console.log('Valid token found in user context.');
          setIsAuthenticated(true);
  
          // Check if user has any of the required roles
          if (!requiredRoles.length || requiredRoles.includes(user.role)) {
            console.log('User has required role:', user.role);
            setHasRole(true);
          } else {
            console.log('User does not have required role:', user.role);
            setHasRole(false);
          }
        } else {
          console.log('No valid token. Making API call to check authentication.');
          const response = await axiosInstance.get(
            '/api/auth/check-auth',
            {
              withCredentials: true,
            }
          );
  
          console.log('API response:', response.data);
  
          const { authenticated, user: userData } = response.data;
  
          if (authenticated) {
            console.log('User is authenticated via API.');
            setIsAuthenticated(true);
            setUser(userData);
  
            if (!requiredRoles.length || requiredRoles.includes(userData.role)) {
              console.log('User has required role:', userData.role);
              setHasRole(true);
            } else {
              console.log('User does not have required role:', userData.role);
              setHasRole(false);
            }
          } else {
            console.log('User is not authenticated.');
            setIsAuthenticated(false);
          }
        }
      } catch (error) {
        console.error('Error during authentication or role check:', error);
        setIsAuthenticated(false);
        logout();
      }
    };
  
    checkAuth();
  }, [requiredRoles, setUser, user?.token, logout]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Display loading indicator while checking auth
  }

  return isAuthenticated && hasRole ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;