// src/App.jsx
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./routes/home/home.component";
import AboutUs from "./components/about-us.component/about-us";
import ServicesDescription from "./components/service-description.component/service-descripition";
import ContactForm from "./components/contact-form.component/contact-form";
import Galleries from "./components/galeries.component/galleries";
import Navigation from "./routes/navigation/navigation.component";
import ImageGallery from "./components/images.components/ImageGallery";

import Login from "./auth/login.component/login";
import AdminDashboard from "./admin.components/dashboard.component/dashboard";

import PrivateRoute from "./routes/private/private-routes";
import Logout from "./auth/logout.component/logout";

// Components created and tested

import GenerateInvoice from "./admin.components/form.component/invoice.component/generate-invoice";
import GenerateProposal from "./admin.components/form.component/proposal.component/generate-prop";
import RegisterEmployee from "./admin.components/form.component/register.component/register-employee.component/register-employee";
import RegisterUser from "./admin.components/form.component/register.component/register-user.component/register-user";
import AdminLayout from "./admin.components/layout";
import MainTable from "./admin.components/tables.component/invoice-proposal.component/table-main";
import Document from "./admin.components/document.component/invoice-proposal.components/document";
import Profile from "./admin.components/document.component/profile.component/profile";


const App = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Navigation showNav={true} />}>
        <Route index element={<Home />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="services" element={<ServicesDescription />} />
        <Route path="galleries" element={<Galleries />} />
        <Route path="contact" element={<ContactForm />} />
        <Route path="gallery/:category" element={<ImageGallery />} />
      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />

      // Protected routes for "owner" and "admin"
<Route element={<PrivateRoute requiredRole={["owner", "admin"]}/>}>
  <Route path="/admin" element={<AdminLayout />}>
    <Route path="dashboard" element={<AdminDashboard />} />
    <Route path="register-employee" element={<RegisterEmployee />} />
    <Route path="register-user" element={<RegisterUser />} />
    <Route path="generate-invoice" element={<GenerateInvoice />} />
    <Route path="generate-invoice/:invoiceId" element={<GenerateInvoice />} />
    <Route path="generate-proposal" element={<GenerateProposal />} />
    <Route path="generate-proposal/:proposalId" element={<GenerateProposal />} />
    <Route path=":tableType" element={<MainTable />} />
    <Route path="profile/:documentType/:documentId" element={<Profile />} />
    <Route path="document/:documentType/:documentId" element={<Document />} />
  </Route>
</Route>

// Protected routes for "account" role, allowing only access to invoices
<Route element={<PrivateRoute requiredRole={["accountant"]}/>}>
  <Route path="/admin" element={<AdminLayout />}>
    {/* Account role can only access invoice-related routes */}
    <Route path="generate-invoice" element={<GenerateInvoice />} />
    <Route path="generate-invoice/:invoiceId" element={<GenerateInvoice />} />
    <Route path=":tableType" element={<MainTable />} /> {/* For displaying invoice tables */}
  </Route>
</Route>
    </Routes>
  );
};

export default App;
