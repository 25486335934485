// src/routes/navigation/navigation.component.jsx
import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './navigation.styles.css';

const Navigation = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation(); // Hook to get the current location

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

    // Determine if the current route is the ImageGallery
    const isImageGalleryPage = location.pathname.startsWith('/gallery');

  return (
    <div className="navigation-container">
      {!isImageGalleryPage && ( // Conditionally render the toggle button
      <button className="toggle-btn" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
      </button> )}
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
      {!isImageGalleryPage && (
      <ul>
          <li><a href="#home" onClick={toggleSidebar}>Home</a></li>
          <li><a href="#about" onClick={toggleSidebar}>About</a></li>
          <li><a href="#testimonials" onClick={toggleSidebar}>Testimonials</a></li>
          <li><a href="#key-services" onClick={toggleSidebar}>Services</a></li>
          <li><a href="#galleries" onClick={toggleSidebar}>Galleries</a></li>
          <li><a href="#vertical-print" onClick={toggleSidebar}>Vertical Print</a></li>
          <li><a href="#contact" onClick={toggleSidebar}>Contact</a></li>
        </ul> )}
        <div className="social-list-nav">
          <a href="https://www.facebook.com/magicrainbowpd" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="https://www.instagram.com/magicrainbow_pd/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://www.linkedin.com/company/100253844" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default Navigation;
