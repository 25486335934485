import React from 'react';


const LogoutMessage = () => (
  <div>

    <div className="text-center auth-title-section">
      <h3 className="text-dark fs-20 fw-medium mb-2">You are Logged Out</h3>
      <p className="text-muted fs-15">See you soon</p>
    </div>
  </div>
);

export default LogoutMessage;
