import React from "react";

const ContactDetails = ({ documentType, documentData, config }) => {
  const documentConfig = config[documentType]; // Get the configuration for the current document type

  if (!documentConfig) {
    return <div>No configuration available for this document type</div>;
  }

  const { fields, optionalFields, sections } = documentConfig;

  // Helper function to render fields
  const renderField = (label, value) => (
    <div className="col-md-6 mb-3">
      <h6 className="text-uppercase fs-13">{label}</h6>
      <p className="fs-14">{value || "N/A"}</p>
    </div>
  );

  return (
    <div className="col-md-12">
      {/* Section: About */}
      <h5 className="fs-16 text-dark fw-semibold mb-3">
        {sections?.about?.label || "Contact Details"}
      </h5>
      <div className="row">
        {sections?.about?.descriptionFields.map((fieldKey) => {
          const fieldValue = documentData?.[fields[fieldKey]];
          return renderField(fieldKey, fieldValue);
        })}
      </div>

      {/* Section: Address Details (if applicable) */}
      {sections?.addressDetails && (
        <>
          <h5 className="fs-16 text-dark fw-semibold mb-3">
            {sections.addressDetails.label}
          </h5>
          <div className="row">
            {sections.addressDetails.fields.map((fieldKey) => {
              const fieldValue = documentData?.[fields[fieldKey]];
              return renderField(fieldKey, fieldValue);
            })}
          </div>
        </>
      )}

      {/* Section: Job Details (for Employees) */}
      {sections?.jobDetails && (
        <>
          <h5 className="fs-16 text-dark fw-semibold mb-3">
            {sections.jobDetails.label}
          </h5>
          <div className="row">
            {sections.jobDetails.fields.map((fieldKey) => {
              const fieldValue = documentData?.[fields[fieldKey]];
              return renderField(fieldKey, fieldValue);
            })}
          </div>
        </>
      )}

      {/* Section: Additional Info (if applicable) */}
      {sections?.additionalInfo && (
        <>
          <h5 className="fs-16 text-dark fw-semibold mb-3">
            {sections.additionalInfo.label}
          </h5>
          <div className="row">
            {sections.additionalInfo.optionalFields.map((optionalKey) => {
              const fieldValue = documentData?.[optionalFields?.[optionalKey]];
              return fieldValue ? renderField(optionalKey, fieldValue) : null;
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ContactDetails;