import React from "react";

const SearchInput = ({ label, placeholder, value, onChange }) => {
  return (
    <div className="p-3 border rounded bg-light">
      <h8>{label}</h8>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="form-control mb-2"
      />
    </div>
  );
};

export default SearchInput;