import React from 'react';

const EducationSection = ({ documentType, documentData, config }) => {
  // Get the configuration for the current document type
  const documentConfig = config[documentType];

  if (!documentConfig || !documentConfig.sections?.educationDetails) {
    return null; // No education details section for this document type
  }

  const { fields } = documentConfig;
  const educationData = documentData.education || [];

  // Helper function to render a single education entry
  const renderEducationItem = (education, index) => (
    <div className="card border mb-0" key={index}>
      <div className="card-body">
        <h4 className="mb-2 fs-18 fw-semibold text-dark">
          {education.degree || "N/A"}
        </h4>
        <h5 className="fs-16 mt-1">
          {education.institution || "N/A"}
        </h5>
        <p>{education.year || "Year not available"}</p>
      </div>
    </div>
  );

  return (
    <div className="row">
      <div className="col-md-12 col-lg-6">
        <h5 className="fs-16 text-dark fw-semibold mb-3 text-capitalize">
          {documentConfig.sections.educationDetails.label || "Education"}
        </h5>
        {educationData.length > 0 ? (
          educationData.map((education, index) =>
            renderEducationItem(education, index)
          )
        ) : (
          <p>No education details available for {documentType}</p>
        )}
      </div>
    </div>
  );
};

export default EducationSection;