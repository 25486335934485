import React, {forwardRef} from "react";

const DynamicFormField = forwardRef(({ field, value, onChange, options, onKeyDown, errors },ref) => {
  return (
    <div className="mb-3">
      <label htmlFor={field.name} className="form-label">
        {field.label}
      </label>
      {field.type === "textarea" ? (
        <textarea
          className={`form-control ${errors[field.name] ? "is-invalid" : ""}`} // Add 'is-invalid' class if there are errors
          id={field.name}
          name={field.name}
          placeholder={field.placeholder}
          required={field.required}
          value={value || ""} // Ensure a controlled component
          onChange={onChange}
        />
      ) : field.type === "select" ? (
        <select
          className="form-select"
          id={field.name}
          name={field.name}
          value={value}
          onChange={onChange}
          disabled={field.dependentOn && options.length === 0} // Disable if field has a dependency
        >
          <option value=""> {field.label}</option>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      ) : field.type === "checkbox" ? (
          <div className="form-check">
            <input
              type="checkbox"
              className={`form-check-input ${
                errors[field.name] ? "is-invalid" : ""
              }`}
              id={field.name}
              name={field.name}
              checked={value || false} // Ensure controlled component for checkbox
              onChange={onChange}
              ref={ref} // Forward ref to checkbox
            />
            <label className="form-check-label" htmlFor={field.name}>
              {field.label}
            </label>
            {errors?.[field.name] && (
              <div className="invalid-feedback">{errors[field.name]}</div>
            )}
          </div>
        ) : field.type === "radio" ? (
          options.map((option, index) => (
            <div className="form-check" key={`${field.name}-${index}`}>
              <input
                type="radio"
                className={`form-check-input ${
                  errors[field.name] ? "is-invalid" : ""
                }`}
                id={`${field.name}-${index}`}
                name={field.name}
                value={option.value}
                checked={value === option.value}
                onChange={onChange}
                ref={ref} // Forward ref to radio button
              />
              <label
                className="form-check-label"
                htmlFor={`${field.name}-${index}`}
              >
                {option.label}
              </label>
              {index === options.length - 1 && errors?.[field.name] && (
                <div className="invalid-feedback">{errors[field.name]}</div>
              )}
            </div>
          ))
      ) : field.type === "file" ? (
        <input
          type="file"
          className={`form-control ${errors[field.name] ? "is-invalid" : ""}`}
          id={field.name}
          name={field.name}
          required={field.required}
          onChange={onChange}
        />
      ) : (
        <input
          type={field.type}
          id={field.name}
          name={field.name}
          className="form-control"
          value={value}
          onChange={onChange}
          min={field.min}
          max={field.max}
          step={field.step}
          onKeyDown={onKeyDown}  // Attach key press handler
          ref={ref}  // Forward the ref to the input element
        />
      )}
        {/* Conditionally render error message if an error exists for the field */}
        {errors?.[field.name] && (
          <div className="text-danger">{errors[field.name]}</div>
        )}
    </div>
  );
});

export default DynamicFormField;
