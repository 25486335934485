import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useFetchDocument from "../utils/use-fetch-document";
import ProfileHeader from "./profile-header";
import ProfileTabs from "./profile-tab"; // Import the new ProfileTabs component
import documentConfig from "./utils/json/profile-config.json"; // JSON config for dynamic fields

const Profile = () => {
  const { documentType, documentId } = useParams();
  const { documentData, loading } = useFetchDocument(documentType, documentId);

  useEffect(() => {
    if (documentData) {
      console.log(`Profile: ${documentType} Data:`, documentData);
    }
  }, [documentData, documentType]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!documentData) {
    return <div>No data available</div>;
  }

  return (
    <div id="app-layout">
      <div className="content-page">
        <div className="content">
          <div className="container-xxl">
            <div className="py-3 d-flex align-items-sm-center flex-sm-row flex-column">
              <div className="flex-grow-1">
                <h4 className="fs-18 fw-semibold m-0">Profile</h4>
              </div>
              <div className="text-end">
                <ol className="breadcrumb m-0 py-0">
                  <li className="breadcrumb-item">
                    <button>{documentType.toLocaleUpperCase()}</button>
                  </li>
                  <li className="breadcrumb-item active">Profile</li>
                </ol>
              </div>
            </div>

            {/* Profile Header */}
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="panel-body">
            <ProfileHeader
              documentType={documentType}
              documentData={documentData}
              config={documentConfig}
            />

            {/* Profile Tabs */}
            <ProfileTabs
              documentType={documentType}
              documentData={documentData}
              config={documentConfig}
            />
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>

  );
};

export default Profile;