// SearchBar.js
import React from 'react';

const SearchBar = () => {
  return (
    <div className="position-relative topbar-search">
      <input
        type="text"
        className="form-control bg-light bg-opacity-75 border-light ps-4"
        placeholder="Search..."
      />
      <i className="mdi mdi-magnify fs-16 position-absolute text-muted top-50 translate-middle-y ms-2"></i>
    </div>
  );
};

export default SearchBar;