// Topbar.js
import React from 'react';
import NotificationDropdown from './notification';
import UserDropdown from './user-dropdown';
import SearchBar from './search-bar';


const Topbar = () => {
    return (
      <div className="topbar-custom">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <ul className="list-unstyled topnav-menu mb-0 d-flex align-items-center">
              <li>
                <button className="button-toggle-menu nav-link">
                  <i data-feather="menu" className="noti-icon"></i>
                </button>
              </li>
              <li className="d-none d-lg-block">
                <SearchBar />
              </li>
            </ul>
            <ul className="list-unstyled topnav-menu mb-0 d-flex align-items-center">
              <NotificationDropdown />
              <UserDropdown />
            </ul>
          </div>
        </div>
      </div>
    );
  };
  
  export default Topbar;