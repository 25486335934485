import React from "react";


const AdminDashboard = () => {
  return (
    <div id="app-layout">

      <div className="content-page">
        <div className="content">
          {/* Start Content */}
          <div className="container-xxl">
            <div className="py-3 d-flex align-items-sm-center flex-sm-row flex-column">
              <div className="flex-grow-1">
                <h4 className="fs-18 fw-semibold m-0">Dashboard</h4>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-8">
                        <p className="text-muted mb-3 fw-semibold">
                         
                        </p>
                        <h4 className="m-0 mb-3 fs-18"></h4>
                        <p className="mb-0 text-muted">
                          <span className="text-success me-2">
                            <i className="mdi mdi-arrow-top-right text-success"></i>
                            
                          </span>
                         
                        </p>
                      </div>
                      <div className="col-4">
                        <div className="d-flex justify-content-center">
                          <div id="total_space" className="me-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-8">
                        <p className="text-muted mb-3 fw-semibold">
                         
                        </p>
                        <h4 className="m-0 mb-3 fs-18"></h4>
                        <p className="mb-0 text-muted">
                          <span className="text-danger me-2">
                            <i className="mdi mdi-arrow-bottom-left text-danger"></i>
                           
                          </span>
                          
                        </p>
                      </div>
                      <div className="col-4">
                        <div className="d-flex justify-content-center">
                          <div id="video_space" className="me-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-8">
                        <p className="text-muted mb-3 fw-semibold">
                          Listen Music
                        </p>
                        <h4 className="m-0 mb-3 fs-18">258 GB Space</h4>
                        <p className="mb-0 text-muted">
                          <span className="text-success me-2">
                            <i className="mdi mdi-arrow-top-right text-success"></i>{" "}
                            + 45%
                          </span>
                          last month
                        </p>
                      </div>
                      <div className="col-4">
                        <div className="d-flex justify-content-center">
                          <div id="music_space" className="me-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-8">
                        <p className="text-muted mb-3 fw-semibold">
                          Document File
                        </p>
                        <h4 className="m-0 mb-3 fs-18"></h4>
                        <p className="mb-0 text-muted">
                          <span className="text-success me-2">
                            <i className="mdi mdi-arrow-top-right text-success"></i>{" "}
                          
                          </span>
                        
                        </p>
                      </div>
                      <div className="col-4">
                        <div className="d-flex justify-content-center">
                          <div id="document_space" className="me-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-xl-8">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="card-title mb-0">Repeat Customer Rate</h5>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="justify-content-center">
                      <div id="customer_rate" className="apex-charts"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-xl-4">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="card-title mb-0">Top Customers</h5>

                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div className="justify-content-center">
                      <div className="table-responsive card-table">
                        <table className="table align-middle table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <td>
                                <div className="d-flex align-items-center my-1">
                                  <div className="avatar-sm rounded me-3 align-items-center justify-content-center d-flex">
                                    <img
                                      src="assets/images/users/user-11.jpg"
                                      className="img-fluid rounded-circle"
                                      alt=""
                                    />
                                  </div>
                                  <div>
                                    <h5 className="fs-14 mb-1"></h5>
                                    <span className="text-muted">
                                     
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="fw-normal my-1"></p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="d-flex align-items-center my-1">
                                  <div className="avatar-sm rounded me-3 align-items-center justify-content-center d-flex">
                                    <img
                                      src="assets/images/users/user-12.jpg"
                                      className="img-fluid rounded-circle"
                                      alt=""
                                    />
                                  </div>
                                  <div>
                                    <h5 className="fs-14 mb-1">
                                  
                                    </h5>
                                    <span className="text-muted">
                                     
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="fw-normal my-1"></p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="d-flex align-items-center my-1">
                                  <div className="avatar-sm rounded me-3 align-items-center justify-content-center d-flex">
                                    <img
                                      src="assets/images/users/user-13.jpg"
                                      className="img-fluid rounded-circle"
                                      alt=""
                                    />
                                  </div>
                                  <div>
                                    <h5 className="fs-14 mb-1"></h5>
                                    <span className="text-muted">
                                     
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="fw-normal my-1"></p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="d-flex align-items-center my-1">
                                  <div className="avatar-sm rounded me-3 align-items-center justify-content-center d-flex">
                                    <img
                                      src="assets/images/users/user-14.jpg"
                                      className="img-fluid rounded-circle"
                                      alt=""
                                    />
                                  </div>
                                  <div>
                                    <h5 className="fs-14 mb-1"></h5>
                                    <span className="text-muted">
                                      
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="fw-normal my-1"></p>
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">
                                <div className="d-flex align-items-center my-1">
                                  <div className="avatar-sm rounded me-3 align-items-center justify-content-center d-flex">
                                    <img
                                      src="assets/images/users/user-15.jpg"
                                      className="img-fluid rounded-circle"
                                      alt=""
                                    />
                                  </div>
                                  <div>
                                    <h5 className="fs-14 mb-1"></h5>
                                    <span className="text-muted">
                                      
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="border-0">
                                <p className="fw-normal my-1"></p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-xl-6">
                <div className="card overflow-hidden">
                  <div className="card-header border-0">
                    <div className="d-flex align-items-center">
                      <h5 className="card-title mb-0">Top Selling Products</h5>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="justify-content-center">
                      <div className="table-responsive card-table">
                        <table className="table align-middle table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th className="py-2 border-0">Product</th>
                              <th className="py-2 border-0">Progress</th>
                              <th className="py-2 border-0">Status</th>
                              <th className="py-2 border-0">Sales</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="p-1 me-3 align-items-center justify-content-center d-flex">
                                    <img
                                      src="assets/images/products/dresses.jpg"
                                      className="img-fluid rounded"
                                      alt="product"
                                      width="40px"
                                    />
                                  </div>
                                  <div>
                                    <h5 className="fs-14 my-1">Dresses</h5>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="fs-14 my-1 fw-normal"></p>
                              </td>
                              <td>
                                <span className="badge bg-success-subtle fs-13 px-2 rounded-5 text-success fw-medium">
                                
                                </span>
                              </td>
                              <td>
                                <p className="fs-14 my-1 fw-normal"></p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="p-1 me-3 align-items-center justify-content-center d-flex">
                                    <img
                                      src="assets/images/products/bags.jpg"
                                      className="img-fluid rounded"
                                      alt="product"
                                      width="40px"
                                    />
                                  </div>
                                  <div>
                                    <h5 className="fs-14 my-1"></h5>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="fs-14 my-1 fw-normal"></p>
                              </td>
                              <td>
                                <span className="badge bg-warning-subtle fs-13 px-2 rounded-5 text-warning fw-medium">
                                  Low
                                </span>
                              </td>
                              <td>
                                <p className="fs-14 my-1 fw-normal"></p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="p-1 me-3 align-items-center justify-content-center d-flex">
                                    <img
                                      src="assets/images/products/shoes.jpg"
                                      className="img-fluid rounded"
                                      alt="product"
                                      width="40px"
                                    />
                                  </div>
                                  <div>
                                    <h5 className="fs-14 my-1"></h5>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="fs-14 my-1 fw-normal"></p>
                              </td>
                              <td>
                                <span className="badge bg-secondary-subtle fs-13 px-2 rounded-5 text-secondary fw-medium">
                                  High
                                </span>
                              </td>
                              <td>
                                <p className="fs-14 my-1 fw-normal"></p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="p-1 me-3 align-items-center justify-content-center d-flex">
                                    <img
                                      src="assets/images/products/headphone.jpg"
                                      className="img-fluid rounded"
                                      alt="product"
                                      width="40px"
                                    />
                                  </div>
                                  <div>
                                    <h5 className="fs-14 my-1">Headphone</h5>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="fs-14 my-1 fw-normal"></p>
                              </td>
                              <td>
                                <span className="badge bg-warning-subtle fs-13 px-2 rounded-5 text-warning fw-medium">
                                  Low
                                </span>
                              </td>
                              <td>
                                <p className="fs-14 my-1 fw-normal"></p>
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">
                                <div className="d-flex align-items-center">
                                  <div className="p-1 me-3 align-items-center justify-content-center d-flex">
                                    <img
                                      src="assets/images/products/camera.jpg"
                                      className="img-fluid rounded"
                                      alt="product"
                                      width="40px"
                                    />
                                  </div>
                                  <div>
                                    <h5 className="fs-14 my-1"></h5>
                                  </div>
                                </div>
                              </td>
                              <td className="border-0">
                                <p className="fs-14 my-1 fw-normal"></p>
                              </td>
                              <td className="border-0">
                                <span className="badge bg-success-subtle fs-13 px-2 rounded-5 text-success fw-medium">
                                  Medium
                                </span>
                              </td>
                              <td className="border-0">
                                <p className="fs-14 my-1 fw-normal"></p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-xl-6">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="card-title mb-0">Author Sales</h5>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="justify-content-center">
                      <div id="author_chart" className="apex-charts"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* container-fluid */}
        </div>{" "}
        {/* content */}
        {/* Footer can be added here if needed */}
      </div>
    </div>
  );
};

export default AdminDashboard;
