import React from 'react';

const ExperienceSection = ({ documentType, documentData, config }) => {
  // Get the configuration for the current document type
  const documentConfig = config[documentType];

  if (!documentConfig || !documentConfig.sections?.jobDetails) {
    return null; // No job details section for this document type
  }

  const { fields } = documentConfig;
  const experiences = documentData.experience || [];

  // Helper function to render a single experience item
  const renderExperienceItem = (experience, index) => (
    <li key={index}>
      <h6>{experience.jobTitle || 'N/A'} at {experience.company || 'N/A'}</h6>
      <p className="text-muted">
        {experience.startDate || 'N/A'} - {experience.endDate || 'Present'}
      </p>
      <p>{experience.description || 'No description available'}</p>
    </li>
  );

  return (
    <div className="row">
      <div className="col-md-12 col-lg-6">
        <h5 className="fs-16 text-dark fw-semibold mb-3 text-capitalize">
          {documentConfig.sections.jobDetails.label || 'Work Experience'}
        </h5>
        {experiences.length > 0 ? (
          <ul className="list-unstyled">
            {experiences.map((experience, index) =>
              renderExperienceItem(experience, index)
            )}
          </ul>
        ) : (
          <p>No work experience available for {documentType}</p>
        )}
      </div>
    </div>
  );
};

export default ExperienceSection;