import axios from "axios";

// A helper function to fetch data and dispatch it to the correct action type
export const fetchData = async (url, dispatch, actionType) => {
  try {
    const response = await axios.get(url);

    if (response.data) {
      // Check if the response contains data
      // Ensure the data is in the expected format
      if (Array.isArray(response.data) || typeof response.data === "object") {
        dispatch({ type: actionType, data: response.data });
      } else {
        dispatch({ type: actionType, data: [] }); // or {} depending on expected type
      }
    } else {
      dispatch({ type: actionType, data: [] }); // or {} depending on expected type
    }
  } catch (error) {
    console.error(`Error loading data from ${url}:`, error);
  }
};
