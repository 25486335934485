// utils.js

/**
 * Safely access state properties with default values to avoid undefined errors.
 * @param {any} value - The value to check.
 * @param {any} defaultValue - The default value to return if the initial value is undefined or NaN.
 * @returns {any} - The value itself if it's defined and not NaN, otherwise the default value.
 */
export const safeValue = (value, defaultValue = 0) => {
    return isNaN(value) || value === undefined ? defaultValue : value;
  };
  