// UserDropdown.js
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../../auth/login.component/context/login-context";

const UserDropdown = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  // Handle logout action
  const handleLogout = () => {

    navigate("/logout"); // Redirect to the logout page
  };

  return (
    <li className="dropdown notification-list topbar-dropdown">
      <button
        className="nav-link dropdown-toggle nav-user me-0"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >

        <span className="pro-user-name ms-1">
          {user?.firstname || "User"} <i className="mdi mdi-chevron-down"></i>{" "}
          {/* Display dynamic username */}
        </span>
      </button>

      <div className="dropdown-menu dropdown-menu-end profile-dropdown">
        <div className="dropdown-header noti-title">
          <h6 className="text-overflow m-0">
            Welcome, {user?.firstname || "User"}!
          </h6>{" "}
          {/* Dynamic Welcome message */}
        </div>

        {/* Profile Link */}
        <Link to="/profile" className="dropdown-item notify-item">
          <i className="mdi mdi-account-circle-outline fs-16 align-middle"></i>
          <span>My Account</span>
        </Link>



        <div className="dropdown-divider"></div>

        {/* Logout button */}
        <button
          onClick={handleLogout}
          className="dropdown-item notify-item"
          style={{ cursor: "pointer" }}
        >
          <i className="mdi mdi-location-exit fs-16 align-middle"></i>
          <span>Logout</span>
        </button>
      </div>
    </li>
  );
};

export default UserDropdown;
