// Define initial state with dynamic structure, including 'customers'
export const initialState = {
  filteredData: {
    invoices: [],
    proposals: [],
    customers: [], // Add customers array for filtered customer data
    leads: [],
    employees: [],
    users: [],
  },
  data: {
    invoices: [],
    proposals: [],
    customers: [], // Add customers array for original customer data
    leads: [],
    employees: [],
    users: [],
  },
  tableState: {
    invoices: {
      searchQuery: "",
      dateRange: [null, null],
      statusFilter: "",
    },
    proposals: {
      searchQuery: "",
      dateRange: [null, null],
      statusFilter: "",
    },
    customers: {
      searchQuery: "",
      dateRange: [null, null],
      statusFilter: "",
    },
    leads: {
      searchQuery: "",
      dateRange: [null, null],
      statusFilter: "",
    },
    employees: {
      searchQuery: "",
      dateRange: [null, null],
      statusFilter: "",
    },
    users: {
      searchQuery: "",
      dateRange: [null, null],
      statusFilter: "",
    },
  },
};

// Action types
const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
const SET_DATE_RANGE = "SET_DATE_RANGE";
const SET_STATUS_FILTER = "SET_STATUS_FILTER";
const SET_FILTERED_DATA = "SET_FILTERED_DATA";
const SET_DATA = "SET_DATA";
const CLEAR_FIELDS = "CLEAR_FIELDS";

// Reducer
export const tableReducer = (state, action) => {
  const { tableType, payload } = action;
  
  // Debugging log to see tableType in action
  console.log("Reducer received tableType:", tableType);

  if (!tableType) {
    console.error("tableType is undefined in the action!");
    return state;
  }

  switch (action.type) {
    case SET_SEARCH_QUERY:
      return {
        ...state,
        tableState: {
          ...state.tableState,
          [action.tableType]: {
            ...state.tableState[action.tableType],
            searchQuery: action.payload,
          },
        },
      };

    case SET_DATE_RANGE:
      return {
        ...state,
        tableState: {
          ...state.tableState,
          [action.tableType]: {
            ...state.tableState[action.tableType],
            dateRange: action.payload,
          },
        },
      };

    case SET_STATUS_FILTER:
      return {
        ...state,
        tableState: {
          ...state.tableState,
          [action.tableType]: {
            ...state.tableState[action.tableType],
            statusFilter: action.payload,
          },
        },
      };

    case SET_FILTERED_DATA:
      // Handle filtered data for invoices, proposals, or customers
      return {
        ...state,
        filteredData: {
          ...state.filteredData,
          [tableType]: payload, // Use tableType to update the correct data (invoices, proposals, or customers)
        },
      };

    case SET_DATA:
      // Handle data for invoices, proposals, or customers
      console.log("Reducer setting data for tableType:", tableType, "with payload:", payload);
      return {
        ...state,
        data: {
          ...state.data,
          [tableType]: payload, // Use tableType to update the correct data (invoices, proposals, or customers)
        },
      };

    case CLEAR_FIELDS:
      // Reset the filtered data to the original data for the specified tableType
      return {
        ...state,
        searchQuery: "",
        dateRange: [null, null],
        statusFilter: "",
        filteredData: {
          ...state.filteredData,
          [tableType]: state.data[tableType], // Reset filtered data to original data for that tableType (invoices, proposals, or customers)
        },
      };

    default:
      return state;
  }
};