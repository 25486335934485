import { useState, useEffect } from "react";
import { loadFormConfigs, loadUserFormConfigs } from "../../register.component/utils/load-form-config";
import { useContext } from "react";
import { RegisterFormContext } from "../../register.component/context/register-form";

export const useEmployeeFormConfig = () => {
  const [formConfig, setFormConfig] = useState(null);
  const { dispatch } = useContext(RegisterFormContext);

  useEffect(() => {
    dispatch({ type: "TOGGLE_FORM_TYPE", isEmployeeForm: true });
    const config = loadFormConfigs();
    setFormConfig(config);
  }, [dispatch]);

  return formConfig;
};

export const useUserFormConfig = () => {
    const [formConfig, setFormConfig] = useState(null);
    const { dispatch } = useContext(RegisterFormContext);
  
    useEffect(() => {
      dispatch({ type: "TOGGLE_FORM_TYPE", isEmployeeForm: false });
      const config = loadUserFormConfigs();
      setFormConfig(config);
    }, [dispatch]);
  
    return formConfig;
  };