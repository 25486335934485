
// handle-create-form.js
import axios from "axios";
import { validateRegisterForm } from "./register-validation.js";

export const handleRegister = async (formType, state, dispatch, successCallback, errorCallback) => {
  console.log("handleRegister function called."); 
  // Validate the form fields
  const filteredErrors = validateRegisterForm(state);

  if (Object.keys(filteredErrors).length > 0) {
    dispatch({ type: "SET_ERRORS", errors: filteredErrors });
    return;
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/register-${formType}`,
      state,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(`${formType.charAt(0).toUpperCase() + formType.slice(1)} saved successfully`, response.data);

    // Trigger success callback
    if (successCallback) successCallback(response);

    // Clear form and errors after successful submission
    dispatch({ type: "TOGGLE_MODAL" });
    dispatch({ type: "CLEAR_ERRORS" });
    dispatch({ type: "CLEAR_ALL_FIELDS" });
  } catch (error) {
    console.error(`Error creating ${formType}`, error);
    if (errorCallback) errorCallback(error);
  }
};
