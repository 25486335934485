// src/components/DocumentSummary.jsx

import React from 'react';

const DocumentSummary = ({ documentData }) => {
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <p className="text-end">
            <b>Sub-total:</b> € {documentData.totalBasePrice.toFixed(2)}
          </p>
          <p className="text-end">
            Discount: {documentData.discount}%
          </p>

          <p className="text-end">
            VAT {documentData.vatPercentage.toFixed(2)}%: € {documentData.vatAmount.toFixed(2)}
          </p>
          <hr />
          <h4 className="text-end fw-semibold fs-22">
            Total: € {documentData.finalTotalPrice.toFixed(2)}
          </h4>
        </div>
      </div>
      <hr />
    </>
  );
};

export default DocumentSummary;
