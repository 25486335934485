import React, { useState } from 'react';

const ActionButtons = ({ item, tableType, handleActionClick, handleDeleteClick }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Track if the dropdown is open

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle the dropdown open/close
  };

  const handleAction = (action) => {
    handleActionClick(item, action); // Call the action handler
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  return (
    <div className="dropdown">
      <button
        className="btn btn-sm btn-primary dropdown-toggle me-2"
        type="button"
        onClick={toggleDropdown} // Toggle dropdown on click
        aria-expanded={isDropdownOpen} // Set aria-expanded based on the state
      >
        Actions
      </button>

      <ul className={`dropdown-menu${isDropdownOpen ? ' show' : ''}`}>
        <li>
          <button
            className="dropdown-item"
            onClick={() => handleAction("printed")}
          >
            Mark as Printed
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            onClick={() => handleAction("emailed")}
          >
            Mark as Emailed
          </button>
        </li>

        {/* Conditionally show invoice-specific options */}
        {tableType === "invoices" && (
          <>
            <li>
              <button
                className="dropdown-item"
                onClick={() => handleAction("due for payment")}
              >
                Mark as Due for Payment
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => handleAction("payment received")}
              >
                Mark as Payment Received
              </button>
            </li>
          </>
        )}
      </ul>

      {/* Delete Button with Confirmation */}
      <button
        className="btn btn-sm btn-danger"
        onClick={() => {
          if (window.confirm(`Are you sure you want to delete this ${tableType.slice(0, -1)}?`)) {
            handleDeleteClick(item._id);
          }
        }}
      >
        Delete
      </button>
    </div>
  );
};

export default ActionButtons;