import React, {useContext } from "react";
import { FormContext } from "./context/form-provider";
import { useFormHandlers } from "./hooks/form-handlers";
import DynamicFormField from "./dynamic-form-field"
import expireDateInfoFormConfig from './json/expire-date-form-config.json'

const ExpireDateInfo = () => {
  const { state } = useContext(FormContext);
  const { handleSelectedExpireDate } = useFormHandlers();

  const errors = state.errors || {};

  return(
    <div className="col-lg-6 mt-3">
    <div className="card">
      <div className="card-header">
        <h5 className="card-title mb-0">Expire Date</h5>
      </div>
      <div className="card-body">
      {expireDateInfoFormConfig.fields.map((field) => ( 
        <DynamicFormField
          key={field.name}
          field={field}
          value={state[field.name]}
          onChange={handleSelectedExpireDate}
          errors={errors}
        />
      ))}
      </div>
    </div>
  </div>
)
}
export default ExpireDateInfo

