// src/components/DocumentHeader.jsx

import React from "react";
import companyInfo from "../utils/json/company-info.json";

const DocumentHeader = ({ documentType, documentData }) => {
  // Company information
  const { name: companyName, address: companyAddress, phone: companyPhone, email: companyEmail, vatRegNo } = companyInfo;

  // Customer information
  const customer = documentData.customer || {};
  const lead = documentData.lead || {};
   // Determine whether to use Customer or Lead
   const billingInfo = customer.name ? customer : lead;
   const billingTitle = customer.name ? "Bill To:" : "Estimate To:";

   return (
    <>
      <div className="clearfix">
        {/* Company Information */}
        <div className="float-start">
          <div className="d-flex align-items-center">
            <img
              src="https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/logo/Logo.png"
              alt="logo"
              className="me-2"
              height="50"
            />
          </div>
          <address className="mt-3">
            <h4 className="mb-0 caption fw-semibold fs-18">{companyName}</h4>
            {companyAddress.map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
            <abbr title="Phone">Phone:</abbr> {companyPhone}
            <br />
            <abbr title="Email">Email:</abbr> {companyEmail}
            <br />
            <abbr title="VAT Reg No">VAT Reg No:</abbr> {vatRegNo}
          </address>
        </div>

        {/* Document Information */}
        <div className="float-end">
          <h4 className="fs-18">
            {documentType.charAt(0).toUpperCase() + documentType.slice(1)} #
            <br />
            <strong className="fs-16">{documentData._id}</strong>
          </h4>
        </div>
      </div>

      <hr />

      {/* Billing Information */}
      <div className="row">
        <div className="col-md-12">
          <div className="float-start mt-3">
            <h5>{billingTitle}</h5>
            <address>
              <strong>{billingInfo.name}</strong><br />
              {billingInfo.address01 && <>{billingInfo.address01}<br /></>}
              {billingInfo.address02 && <>{billingInfo.address02}<br /></>}
              {billingInfo.street && <>{billingInfo.street}<br /></>}
              {billingInfo.eircode && <>{billingInfo.eircode}<br /></>}
              {billingInfo.email && (
                <>
                  <abbr title="Email">Email:</abbr> {billingInfo.email}<br />
                </>
              )}
            </address>
          </div>

          {/* Order Details */}
          <div className="float-end mt-3">
            <p className="mb-0">
              <strong>Order Date: </strong>{' '}
              {new Date(documentData.date).toLocaleDateString()}
            </p>
            {documentType === "proposal" && (
              <p className="mt-2 mb-0">
                <strong>Valid Until: </strong>{' '}
                {new Date(documentData.expire).toLocaleDateString()}
              </p>
            )}
            <p className="mt-2 mb-0">
              <strong>Order Status: </strong>{' '}
              <span className="label label-pink">
                {documentData.status}
              </span>
            </p>
            <p className="mt-2 mb-0">
              <strong>Order ID: </strong> #{documentData._id}
            </p>
          </div>
        </div>
      </div>

      <hr />
    </>
  );
};

export default DocumentHeader;
