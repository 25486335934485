import React from 'react'
import { TextAreaField, TextInputField, CheckboxField, RadioField, SelectField, DateField, FileInputField } from './utils/input-type';




// Component to render dynamic form fields
const RegisterDynamicFormFields = ({ formConfig, formValues, handleInputChange, handleCheckboxChange, handleRadioChange, handleFileChange, placeholder, errors }) => {
    // Function to render each form field dynamically based on its type
    const renderFormField = (field) => {
        switch (field.type) {
            case 'text':
            case 'email':
            case 'password':
              return <TextInputField key={field.name} field={field} value={formValues[field.name]} onChange={handleInputChange} errors={errors} />;
            case 'textarea':
              return <TextAreaField key={field.name} field={field} value={formValues[field.name]} onChange={handleInputChange} errors={errors} />;
            case 'checkbox':
              return <CheckboxField key={field.name} field={field} value={formValues[field.name]} onChange={handleCheckboxChange} errors={errors} />;
            case 'radio':
              return <RadioField key={field.name} field={field} value={formValues[field.name]} onChange={handleRadioChange} options={field.options} errors={errors} />;
            case 'select':
              return <SelectField key={field.name} field={field} value={formValues[field.name]} onChange={handleInputChange} options={field.options} errors={errors} />;
            case 'date':
              return <DateField key={field.name} field={field} value={formValues[field.name]} onChange={handleInputChange} errors={errors} />;
            case 'file':
              return <FileInputField key={field.name} field={field} onChange={handleFileChange} errors={errors} />;
            default:
              return null;
          }
    };
  
    return (
      <>
        {formConfig.fields.map((field) => renderFormField(field))}
      </>
    );
  };
  
  export default RegisterDynamicFormFields;