// src/components/DocumentFooter.jsx

import React from "react";
import companyInfo from "../utils/json/company-info.json";


const DocumentFooter = ({ documentType, documentData }) => {
  console.log("DocumentType is:", documentType); 
  const { paymentDetails } = companyInfo;

  // Safely extract selectedPaymentMethod only for invoices
  const selectedPaymentMethod =
    documentType === "invoice" && documentData
      ? documentData.selectedPaymentMethod
      : null;

  const shouldShowBankDetails =
    documentType === "invoice" &&
    selectedPaymentMethod &&
    selectedPaymentMethod !== "Cash";
  console.log("This is:", documentType);

  return (
    <>
      <div className="row mt-4">
        <div className="col-md-12">
          <h5>Payment Information</h5>

          {/* Conditionally display payment method and bank details for invoice */}
          {documentType === "invoice" && (
            <>
              {selectedPaymentMethod ? (
                <p>
                  <strong>Payment Method: </strong>
                  {selectedPaymentMethod}
                </p>
              ) : (
                <p>
                  <strong>Payment Method: </strong> Not specified
                </p>
              )}

              {/* Display bank details only if payment method is not Cash */}
              {shouldShowBankDetails && (
                <p>
                  Payment should be made to:
                  <br />
                  <strong>{paymentDetails.accountName}</strong>
                  <br />
                  {paymentDetails.bankName}
                  <br />
                  IBAN: {paymentDetails.iban}
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DocumentFooter;
