// components/PersonalInfoForm.js
import React, { useContext } from "react";
import { FormContext } from "./context/form-provider";
import { useFormHandlers } from "./hooks/form-handlers";
import DynamicFormField from "./dynamic-form-field";
import personalInfoFormConfig from './json/personal-info-form-config.json'

const PersonalInfForm = () => {
  const { state } = useContext(FormContext)
  const { handleInputChange } = useFormHandlers()

  // Safely access state properties with default values
  const personalInfo = state.personalInfo || {};
  const errors = state.errors || {};

  return(
    <>
      {personalInfoFormConfig.fields.map((field) => (
        <DynamicFormField
          key={field.name}
          field={field}
          value={personalInfo[field.name]}
          onChange={handleInputChange}
          errors={errors}
        />
      ))}
    </>
  )

}

export default PersonalInfForm;
