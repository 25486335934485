import React, { useState } from "react";
import { useSearch } from "../hooks/use-search.js";
import searchFieldsConfig from "./json/search-field-config.json";
import DateRangePicker from "../search.component/data-range-picker";
import StatusFilter from "../search.component/status-filter";
import SearchInput from "../search.component/search-input";

const SearchBar = ({ tableType }) => {
  const {
    state,
    handleDateChange,
    handleSearchChange,
    handleStatusChange,
    clearFields,
  } = useSearch(tableType);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);

  // For debugging
  console.log(`THIS${tableType} context state on the searchBAr:`, state);
  console.log("SearchBar component received tableType:", tableType);
  console.log("SearchBar component received state:", state);

  const renderField = (field) => {
    switch (field.type) {
      case "input":
        return (
          <SearchInput
            key={field.key}
            label={field.label}
            placeholder={field.placeholder}
            value={state[field.key]}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        );
      case "dateRange":
        return (
          <DateRangePicker
            key={field.key}
            label={field.label}
            dateRange={state[field.key]}
            onChange={handleDateChange}
          />
        );
      case "select":
        return (
          <StatusFilter
            key={field.key}
            label={field.label}
            value={state[field.key]}
            options={field.options}
            onChange={(e) => handleStatusChange(e.target.value)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <button
        onClick={() => setIsSearchBarVisible(!isSearchBarVisible)}
        className="btn btn-secondary mb-3"
      >
        {isSearchBarVisible ? "Hide Search" : "Show Search"}
      </button>

      {isSearchBarVisible && (
        <div className="card-body">
          <div className="text-center">
            <div className="row">
              {searchFieldsConfig.map((field) => (
                <div className="col" key={field.key}>
                  {renderField(field)}
                </div>
              ))}
            </div>
          </div>
          {/* Clear Fields Button */}
          <button onClick={clearFields} className="btn btn-secondary mt-3">
            Clear Fields
          </button>
        </div>
      )}
    </>
  );
};

export default SearchBar;
