// src/hooks/useFetchDocument.js

import { useState, useEffect } from 'react';
import axios from '../../../axios-config';

const useFetchDocument = (documentType, documentId) => {
  const [documentData, setDocumentData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch document data dynamically (invoice or proposal)
    const fetchDocumentData = async () => {
      
      try {
         // Determine base route based on documentType
         const baseRoute = documentType === "user" ? "auth" : "admin";
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/${baseRoute}/${documentType}/${documentId}`
        );
        setDocumentData(response.data);
      } catch (error) {
        console.error(`Error fetching ${documentType}:`, error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocumentData();
  }, [documentType, documentId]);

  return { documentData, loading };
};

export default useFetchDocument;