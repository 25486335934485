// register-validation.js

import {
  validateSelectField,
  validateEmail,
  validateName,
  validatePassword,
  validateConfirmPass,
  validateUsername,
} from "../../../validators/index";

// Function to validate form fields
export const validateRegisterForm = (state) => {
  const newErrors = {};

  if (state.isEmployeeForm) {
    // Employee Registration Form Validations
    newErrors.firstname = validateName(state.formValues.firstname, "First Name");
    newErrors.lastname = validateName(state.formValues.lastname, "Last Name");
    newErrors.address01 = validateName(state.formValues.address01, "Address");
    newErrors.ppsNumber = validateName(state.formValues.ppsNumber, "PPS Number");
    newErrors.startDate = validateSelectField(state.formValues.startDate, "Starting Date");
    newErrors.contractHours = validateSelectField(state.formValues.contractHours, "Base Contract");
    newErrors.position = validateSelectField(state.formValues.employeePosition, "Position"); // Corrected from 'position' to 'employeePosition'
  } else {
    // User Registration Form Validations
    newErrors.firstname = validateName(state.formValues.firstname, "First Name");
    newErrors.lastname = validateName(state.formValues.lastname, "Last Name");
    newErrors.username = validateUsername(state.formValues.username, "Username");
    newErrors.email = validateEmail(state.formValues.email, "Email");
    newErrors.password = validatePassword(state.formValues.password, "Password");
    newErrors.confirmPassword = validateConfirmPass(state.formValues.password, state.formValues.confirmPassword);
    newErrors.role = validateSelectField(state.formValues.role, "Role"); // Corrected 'selectedRole' to 'role'
  }

  // Filter out any empty error messages
  return Object.keys(newErrors).reduce((acc, key) => {
    if (newErrors[key]) acc[key] = newErrors[key];
    return acc;
  }, {});
};