// sidebarConfig.js
// index.js or App.js

export const sidebarConfig = [
  {
    title: "Dashboard",
    icon: "bi bi-house",
    path: "/admin/dashboard",
    badge: { text: "9+", className: "bg-success rounded-pill float-end" },
  },
  {
    title: "Widgets",
    icon: "bi bi-app",
    path: "/test",
  },
  {
    title: "Landing",
    icon: "bi bi-globe",
    path: "landing.html",
    external: true,
  },
  {
    title: "Utilities",
    type: "menu-title",
  },
  {
    title: "Registraction",
    icon: "bi bi-people",
    dropdown: true,
    links: [
      { to: "/admin/register-employee", label: "Register Employee" },
      { to: "/admin/register-user", label: "Register User" },
    ],
  },
  {
    title: "Generate Document",
    icon: "bi bi-file-earmark sidebar-icon",
    dropdown: true,
    links: [
      { to: "/admin/generate-proposal", label: "Generate Estimate" },
      { to: "/admin/generate-invoice", label: "Generate Invoice" },
    ],
  },

  {
    title: "Staff Information",
    type: "menu-title",
  },
  {
    title: "Profile",
    icon: "bi bi-table sidebar-icon",
    dropdown: true,
    links: [
      { to: "/admin/users", label: "User" },
      { to: "/admin/employees", label: "Employee" },
    ],
  },
  {
    title: "Documents",
    type: "menu-title",
  },
  {
    title: "Customers",
    icon: "bi bi-table sidebar-icon",
    dropdown: true,
    links: [
      { to: "/admin/customers", label: "Customers" },
      { to: "/admin/leads", label: "Leads" },
    ],
  },
  {
    title: "Accountant",
    type: "menu-title",
  },
  {
    title: "Documents",
    icon: "bi bi-table sidebar-icon",
    dropdown: true,
    links: [
      { to: "/admin/invoices", label: "Invoices" },
      { to: "/admin/proposals", label: "Estimates" },
    ],
  },
  // More menu items...
];
