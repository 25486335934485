import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageLayout from './page-layout';  // HOC for consistent page layout
import useLogout from './use-logout';  // Custom hook for logout logic
import Logo from "./logo";
import LogoutMessage from './logout-msg';
import LogoutButton from './logout-btn';
import LogoutHandle from './utils/logout';

const Logout = () => {

const navigate = useNavigate()

  return (
    <PageLayout title="Logout">
      <div className="container-fluid">
        <div className="row vh-100">
          <div className="col-12">
            <div className="p-0">
              <div className="row d-flex align-items-center">
                <div className="col-md-6 col-xl-6 col-lg-6">
                  <div className="row">
                    <div className="col-md-6 mx-auto">
                      <div className="mb-0 border-0">
                        <Logo />
                        <LogoutMessage />
                        <p className="text-center">
                          You have been logged out.
                        </p>
                        <button
                        className="btn btn-primary mt-3"
                        onClick={() => navigate("/login")}
                      >
                        Login
                      </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-6 col-lg-6 p-0 vh-100 d-flex justify-content-center account-page-bg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Logout;