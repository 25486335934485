// Initial state setup with proper structure
export const registerInitialState = {
  formValues: {
    firstname: "",
    lastname: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    role: "",
    address01: "", // Used for employee registration
    address02: "", // Used for employee registration
    street: "", // Used for employee registration
    eircode: "", // Used for employee registration
    ppsNumber: "", // Used for employee registration
    startDate: "", // Used for employee registration
    contractHours: "", // Used for employee registration
    employeePosition: "", // Used for employee registration
    additionalInfo: "", // Used for employee registration
    resume: null, // File fields should be initialized as null or empty string
  },
  errors: {},
  isEmployeeForm: false, // Toggle between user and employee form
};

export const registerFormReducer = (state, action) => {
  switch (action.type) {
    case "SET_FORM_VALUES":
      return {
        ...state,
        formValues: { ...state.formValues, [action.field]: action.value },
      };
    case "SET_ERRORS":
      return { ...state, errors: action.errors };
    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: {},
      };
    case "SET_EMPLOYEE_POSITION":
      return { ...state, employeePosition: action.data || [] };
    case "SET_SELECTED_POSITION":
      return {
        ...state,
        formValues: { ...state.formValues, role: action.role }, // Set role in formValues
        selectedRole: action.role,
      };
    case "SET_EMPLOYEE_ROLES":
      return { ...state, employeeRoles: action.data || [] };
    case "SET_SELECTED_ROLE":
      return {
        ...state,
        formValues: { ...state.formValues, role: action.role }, // Set role in formValues
        selectedRole: action.role,
      };
    case "TOGGLE_FORM_TYPE":
      // Toggle between user registration form and employee registration form
      return {
        ...state,
        isEmployeeForm: action.isEmployeeForm,
        formValues: action.isEmployeeForm
          ? {
              // Default values for employee form
              firstname: "",
              lastname: "",
              ppsNumber: "",
              startDate: "",
              contractHours: "",
              employeePosition: "",
              additionalInfo: "",
              address01: "", // Resetting address for employee form
              address02: "", // Resetting address for employee form
              resume: null,
            }
          : {
              // Default values for user registration form
              firstname: "",
              lastname: "",
              email: "",
              username: "",
              password: "",
              confirmPassword: "",
              role: "",
            },
      };
    case "CLEAR_ALL_FIELDS":
      // Clear all form fields based on the current form type
      return {
        ...state,
        formValues: state.isEmployeeForm
          ? {
              firstname: "",
              lastname: "",
              ppsNumber: "",
              startDate: "",
              contractHours: "",
              position: "",
              additionalInfo: "",
            }
          : {
              firstname: "",
              lastname: "",
              email: "",
              username: "",
              role: "",
              password: "",
              confirmPassword: "",
            },
        errors: {},
        
      };
    default:
      return state;
  }
};

/**
 * 1. Explanation of Changes
	•	Flexible Initial State: The initialState now includes fields for both user and employee registration, allowing the form to be toggled dynamically.
	•	Dynamic Form Value Update: The SET_FORM_VALUES action now updates form values dynamically based on the field name and value.
	•	Toggle Form Type: The TOGGLE_FORM_TYPE action allows you to switch between user registration and employee registration forms. This helps in using the same form component for different registration types.
	•	Clear Fields Based on Form Type: The CLEAR_ALL_FIELDS action clears all form fields depending on the current form type, ensuring proper reset for both user and employee forms.
 */
