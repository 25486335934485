// components/ServiceForm.js
import React, { useContext, useEffect } from "react";
import { fetchData } from "./fetch-data";
import { FormContext } from "./context/form-provider";
import DynamicFormField from "./dynamic-form-field";
import serviceFormConfig from "./json/service-form-config.json";
import { useFormHandlers } from "./hooks/form-handlers";

const ServiceForm = () => {
  const { state, dispatch } = useContext(FormContext);
  const {
    handleServiceChange,
    handleSpecificServiceChange,
    handlePriceChange,
  } = useFormHandlers();
  const errors = state.errors || {};

  useEffect(() => {
    fetchData("/serviceData.json", dispatch, "SET_SERVICE_DATA");
  }, [dispatch]);

  return (
    <>
      {serviceFormConfig.fields.map((field) => (
        <DynamicFormField
          key={field.name}
          field={field}
          value={
            field.name === "selectedService"
              ? state.selectedService
              : field.name === "specificService"
              ? state.specificServices
              : ""
          }
          onChange={
            field.name === "selectedService"
              ? handleServiceChange
              : field.name === "specificService"
              ? handleSpecificServiceChange
              : handlePriceChange
          }
          options={
            field.name === "selectedService"
              ? Object.keys(state.serviceData)
              : state.specificServices
          }
          errors={errors}
        />
      ))}
      {/* Additional components for managing price and service summary can be added here */}
    </>
  );
};

export default ServiceForm;
