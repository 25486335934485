import React from "react";

const StatusFilter = ({ label, value, options, onChange }) => {
  return (
    <div className="p-3 border rounded bg-light">
      <h8>{label}</h8>
      <select
        value={value}
        onChange={onChange}
        className="form-select"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StatusFilter;