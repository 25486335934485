// components/InvoiceForm.js
import React, { useContext } from "react";
import { useFormHandlers } from "../hooks/form-handlers";
import { FormContext } from "../context/form-provider";
import PersonalInfForm from "../personal-info";
import LocationForm from "../location-info";
import ServiceForm from "../service-info";
import DateInfo from "../date";
import ExpireDateInfo from "../expire-date";
import ItemSummary from "../item-summary";

const ProposalForm = () => {
  const { state } = useContext(FormContext);
  const {
    handleInputChange,
    handleSelectedDate,
    handleServiceChange,
    handleSpecificServiceChange,
    handlePriceChange,
    handleSavePrice,
    handleDeleteService,
    handleCountyChange,
    handleCityChange,
  } = useFormHandlers();

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Information</h5>
        </div>
        <div className="card-body">
          <div className="row">
            {/* Left Column Form */}
            <div className="col-lg-6">
              <form>
                {/* Personal Info Fields */}
                <PersonalInfForm
                  personalInfo={state?.personalInfo || {}}
                  handleInputChange={handleInputChange}
                  errors={state.errors}
                />
                {/* Location Fields */}
                <LocationForm
                  selectedCounty={state.selectedCounty}
                  selectedCity={state.selectedCity}
                  handleCountyChange={handleCountyChange}
                  handleCityChange={handleCityChange}
                  errors={state.errors}
                />
              </form>
            </div>

            {/* Right Column Form */}
            <div className="col-lg-6">
              <form>
                {/* Date */}
                <DateInfo
                  DateInfo={state.date}
                  handleSelectedDate={handleSelectedDate}
                  errors={state.errors}
                />
                {/* Service Selection */}
                <ServiceForm
                  selectedService={state.selectedService}
                  specificServices={state.specificServices}
                  handleServiceChange={handleServiceChange}
                  handleSpecificServiceChange={handleSpecificServiceChange}
                  handlePriceChange={handlePriceChange}
                  handleSavePrice={handleSavePrice}
                  handleDeleteService={handleDeleteService}
                  errors={state.errors}
                />
                <ItemSummary
                  selectedSpecificServices={state.selectedSpecificServices}
                  editingIndex={state.editingIndex}
                  priceInput={state.priceInput}
                  handlePriceChange={handlePriceChange}
                  handleSavePrice={handleSavePrice}
                  handleDeleteService={handleDeleteService}
                  errors={state.errors}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProposalForm;
