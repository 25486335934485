
import React, {createContext, useReducer} from 'react'
import {registerFormReducer ,registerInitialState} from "../hooks/register-reduce"

// Create the context for managin Registration state globally

export const RegisterFormContext = createContext();

// Provider component to wrap the application and provide invoice state



export const RegisterFormProvider =({children}) => {
  const [state, dispatch] = useReducer(registerFormReducer, registerInitialState);

  return(
      <RegisterFormContext.Provider value ={{state, dispatch}}>
          {children}
      </RegisterFormContext.Provider>
  )
}
