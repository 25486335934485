/*
The InvoiceContext.jsx file is crucial for managing global state in a React application. 
It leverages the Context API and useReducer hook to create a centralized state management system for the invoice generation process
*/

import React, { createContext, useReducer } from "react";
import { formReducer, initialState } from "../hooks/form-reducer";


// Create the context for managin invoice state globally
export const FormContext = createContext();


// Provider component to wrap the application and provide invoice state

/*
The InvoiceProvider is a custom component that wraps the entire application 
(or the part of the application that needs access to the invoice state). 
It uses the useReducer hook to initialize the state and then provides this 
state and the dispatch function to all child components via the InvoiceContext.Provider.
*/
export const FormProvider = ({ children }) => {
    //dispatch:A function that allows components to send actions to the invoiceReducer to update the state.
  const [state, dispatch] = useReducer(formReducer, initialState);


  return (
    <FormContext.Provider value={{ state, dispatch }}>
      {children}
    </FormContext.Provider>
  );
};




//Why This Approach?
/**
 * Centralized State Management: By combining Context API and useReducer, 
 * the InvoiceContext.jsx file centralizes the state management for the invoice generation process. 
 * This makes it easier to manage, debug, and extend the application as it grows.
 * 
 * Avoiding Prop Drilling: The use of Context API allows components to access the state and dispatch actions without passing props through every 
 * level of the component tree. This reduces the complexity of the code and makes it more maintainable.
 * 
 * Scalability: As new features are added to the invoice generation process, 
 * additional state and actions can be integrated into the invoiceReducer. 
 * The centralized state management makes it easy to scale the application without significant refactoring.
 * 
 * Modern React Patterns: Using useReducer with Context API is a modern React pattern that aligns with best practices 
 * for managing complex state in larger applications. 
 * This approach leverages React’s strengths to provide a clean, maintainable, and scalable solution for state management.
 */