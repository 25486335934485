import React from "react";
import { useEffect, useState } from "react";
import Modal from "react-modal";

import axios from "axios";

import "./ImageGallery.css";
import { useParams, Link } from "react-router-dom";



const ImageGallery = () => {
  const  { category } = useParams();
  const [images, setImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    Modal.setAppElement('#root'); // Ensure that #root is your actual root element ID
  }, []);
  

  useEffect(() => {
    if (!category) {
      console.error('Category is undefined');
      return;
    }
    const lowercaseCategory = category.toLowerCase(); // Convert category to lowercase
    console.log(`Fetching images for category: ${lowercaseCategory}`); // Log the category

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/images/${lowercaseCategory}`;
    console.log(`Fetching images from: ${apiUrl}`); // Debugging

    axios
      .get(apiUrl, { 
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data); // Log response to debug
        setImages(response.data);
      })
      .catch((err) => console.error('Error fetching images:', err));
  }, [category]);

  const openModal = (image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  const goToPrevious = () => {
    const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    setSelectedImage(images[newIndex]);
  };

  const goToNext = () => {
    const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    setSelectedImage(images[newIndex]);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <div className="images-section">
      <div className="galleries-header">
        <h1 className="galleries-heading">{capitalizeFirstLetter(category)} Gallery</h1>
        <div className="galleries-underline"></div>

      </div>
      <div className="return-link">
      <span >{capitalizeFirstLetter(category)} Gallery /</span>
        <Link to="/">Home</Link>
      </div>
      <div className="gallery">
        {images.map((image, index) => (
          <div
            key={image._id}
            className="gallery-item"
            onClick={() => openModal(image, index)}
          >
            <img src={image.url} alt={image.title} className="thumbnail" />
          </div>
        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        {selectedImage && (
          <div className="modal-content">
            <div className="modal-header">
              <span className="close-btn" onClick={closeModal}>
                &times;
              </span>
            </div>
            <div className="modal-body">
              <span className="arrow left-arrow" onClick={goToPrevious}>
                &#9664;
              </span>
              <div className="image-container">
                <img
                  src={selectedImage.url}
                  alt={selectedImage.title}
                  className="full-image"
                />
              </div>
              <span className="arrow right-arrow" onClick={goToNext}>
                &#9654;
              </span>
            </div>
          </div>
        )}
      </Modal>

    </div>
  );
};

export default ImageGallery;
