import { useContext } from "react";
import { FormContext } from "../context/form-provider";
import { handleCreateDocument } from "../utils/create-document";
import axios from "../../../axios-config";

export const useFormHandlers = () => {
  const { state, dispatch } = useContext(FormContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: "SET_PERSONAL_INFO",
      field: name,
      value,
    });
  };

  const handleSelectedDate = (e) => {
    const selectedDate = e.target.value;
    dispatch({ type: "SET_SELECTED_DATE", date: selectedDate });
  };

  const handleSelectedExpireDate = (e) => {
    const selectedExpireDate = e.target.value;
    dispatch({ type: "SET_SELECTED_EXPIRE_DATE", expire: selectedExpireDate });
  };

  const handleServiceChange = (e) => {
    dispatch({ type: "SET_SELECTED_SERVICE", service: e.target.value });
  };

  const handleSpecificServiceChange = (e) => {
    const specificService = e.target.value;
    if (
      specificService &&
      !state.selectedSpecificServices.some(
        (s) => s.specificService === specificService
      )
    ) {
      const newService = {
        service: state.selectedService,
        specificService,
        price: 0,
        basePrice: 0,
      };
      dispatch({
        type: "SET_SELECTED_SPECIFIC_SERVICES",
        services: [...state.selectedSpecificServices, newService],
      });
      dispatch({
        type: "SET_EDITING_INDEX",
        index: state.selectedSpecificServices.length,
      });
      dispatch({
        type: "SET_PRICE_INPUT",
        value: "",
      });
    }
  };

  const handlePriceChange = (e) => {
    dispatch({
      type: "SET_PRICE_INPUT",
      value: e.target.value,
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSavePrice(state.editingIndex);
    }
  };

  const handleSavePrice = () => {
    const priceWithVAT = parseFloat(state.priceInput);
    const basePrice = priceWithVAT / (1 + state.vatPercentage / 100);

    const updatedServices = [...state.selectedSpecificServices];
    updatedServices[state.editingIndex] = {
      ...updatedServices[state.editingIndex],
      price: priceWithVAT,
      basePrice: basePrice,
    };

    dispatch({
      type: "SET_SELECTED_SPECIFIC_SERVICES",
      services: updatedServices,
    });

    const totalBasePrice = updatedServices.reduce(
      (acc, curr) => acc + curr.basePrice,
      0
    );
    const finalTotalPrice = updatedServices.reduce(
      (acc, curr) => acc + curr.price,
      0
    );
    const totalVatAmount = finalTotalPrice - totalBasePrice;

    dispatch({
      type: "SET_TOTALS",
      totalBasePrice,
      vatAmount: totalVatAmount,
      finalTotalPrice,
    });

    dispatch({ type: "CALCULATE_TOTALS", services: updatedServices });
  };

  const handleDeleteService = (index) => {
    const updatedServices = state.selectedSpecificServices.filter(
      (_, i) => i !== index
    );
    dispatch({
      type: "SET_SELECTED_SPECIFIC_SERVICES",
      services: updatedServices,
    });

    const totalBasePrice = updatedServices.reduce(
      (acc, curr) => acc + curr.basePrice,
      0
    );
    const finalTotalPrice = updatedServices.reduce(
      (acc, curr) => acc + curr.price,
      0
    );
    const totalVatAmount = finalTotalPrice - totalBasePrice;

    dispatch({
      type: "SET_TOTALS",
      totalBasePrice,
      vatAmount: totalVatAmount,
      finalTotalPrice,
    });

    dispatch({ type: "CALCULATE_TOTALS", services: updatedServices });
  };

  const handleCountyChange = (e) => {
    dispatch({ type: "SET_SELECTED_COUNTY", county: e.target.value });
  };

  const handleCityChange = (e) => {
    dispatch({ type: "SET_SELECTED_CITY", city: e.target.value });
  };
  // Handler for toggling VAT edit mode
  const toggleVatEdit = () => {
    dispatch({ type: "TOGGLE_VAT_EDIT" });
  };

  // Handler for VAT percentage change
  const handleVatChange = (e) => {
    dispatch({ type: "SET_VAT_PERCENTAGE", value: parseFloat(e.target.value) });
  };

  // Function to close the modal by dispatching an action to toggle modal state
  const closeModal = () => {
    dispatch({ type: "TOGGLE_MODAL", data: false });
  };

  // Function to handle the form submission
  const handleCreateProposal = (e) => {
    e.preventDefault();
    handleCreateDocument();
  };





  const handlePaymentChange = (e) => {
    dispatch({ type: "SET_SELECTED_PAYMENT_METHOD", method: e.target.value });
  };



    // Corrected updateStatus function
  const updateStatus = async (documentType, documentId, status) => {
    const url = documentType === "invoice" 
      ? `${process.env.REACT_APP_API_URL}/api/admin/invoices/${documentId}` 
      : `${process.env.REACT_APP_API_URL}/api/admin/proposals/${documentId}`;
    
    try {
      const response = await axios.put(url, { status });
      console.log(`${documentType} status updated successfully:`, response.data);
  
      // Dispatch the correct action based on the document type
      const actionType = documentType === "invoice" ? "SET_INVOICE_STATUS" : "SET_PROPOSAL_STATUS";
      dispatch({ type: actionType, data: status });
    } catch (error) {
      console.error(`Error updating ${documentType} status:`, error);
      throw error;
    }
  };

  const handleUserChoice = async (choice, formType) => {
    console.log(`handleUserChoice called with choice: ${choice} and formType: ${formType}`); // Debugging
  
    const newStatus = choice === "print" ? "printed" : "emailed";
    console.log("New status determined:", newStatus); // Debugging
  
    // Dynamically select the document (invoice or proposal) based on formType
    const selectedDocument = formType === "invoice" ? state.invoice : state.proposal;
  
    if (!selectedDocument) {
      console.log(`No ${formType} selected, state.${formType} is null or undefined`);
      return;
    }
  
    console.log(`${formType.charAt(0).toUpperCase() + formType.slice(1)} ID found:`, selectedDocument._id); // Debugging
  
    try {
      // Update the status of the selected document (invoice or proposal)
      await updateStatus(formType, selectedDocument._id, newStatus);
      console.log(`${formType.charAt(0).toUpperCase() + formType.slice(1)} status successfully updated in frontend.`);
    } catch (error) {
      console.error(`Failed to update ${formType} status in frontend:`, error);
    }
  
    closeModal();
  };



  return {
    handleInputChange,
    handleSelectedDate,
    handleServiceChange,
    handleSpecificServiceChange,
    handlePriceChange,
    handleSavePrice,
    handleDeleteService,
    handleCountyChange,
    handleCityChange,
    toggleVatEdit,
    handleVatChange,
    handleKeyPress,
    closeModal,
    handleCreateProposal,
    handleSelectedExpireDate,
    handlePaymentChange,
    handleUserChoice,
    updateStatus,
  };
};
