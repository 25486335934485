import React, {useContext } from "react";
import { FormContext } from "./context/form-provider";
import { useFormHandlers } from "./hooks/form-handlers";
import DynamicFormField from "./dynamic-form-field"
import dateInfoFormConfig from './json/data-info-form-config.json'

const DateInfo = () => {
  const { state } = useContext(FormContext);
  const { handleSelectedDate } = useFormHandlers();

  const errors = state.errors || {};

  return(
    <>
      {dateInfoFormConfig.fields.map((field) => ( 
        <DynamicFormField
          key={field.name}
          field={field}
          value={state[field.name]}
          onChange={handleSelectedDate}
          errors={errors}
        />
      ))}
    </>
  )
}

export default DateInfo

