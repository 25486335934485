import React from 'react';


const LogoutMessage = () => (
  <div>
    <div className="maintenance-img text-center pt-3">
      <img src="https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/logo/Logo.png" height="72" alt="svg-logo" />
    </div>
 
  </div>
);

export default LogoutMessage;
