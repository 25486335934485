import axiosInstance from "../../axios-config";

// Dynamic function to fetch table data based on the tableType (invoices, proposals)
export const fetchTableData = async (tableType = "invoices", queryParams = {}) => {
  try {
    // Set the appropriate API URL based on the tableType
    const endpoint = tableType === "invoices"
      ? `/api/admin/invoices`
      : `/api/admin/proposals`;

    // Optional: Construct query string based on provided query parameters
    const queryString = new URLSearchParams(queryParams).toString();

    // Log the final endpoint URL and query parameters for debugging
    console.log(`Fetching data from: ${endpoint}?${queryString}`);

    // Make the API request to the appropriate endpoint
    const response = await axiosInstance.get(`${endpoint}?${queryString}`);
    
    // Log the fetched data for debugging
    console.log(`Fetched ${tableType} data:`, response.data);

    // Return the fetched data
    return response.data;
  } catch (error) {
    console.error(`Error fetching ${tableType} data:`, error);
    return [];
  }
};