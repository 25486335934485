import React from 'react';
import { Link } from 'react-router-dom';
import './galleries.styles.css';

const Galleries = () => {
  return (
    <div className="galleries" id="section_gallery">
      <div className="galleries-header">
        <h1 className="galleries-heading">Gallery</h1>
        <div className="galleries-underline"></div>
      </div>
      <div className="cards-wrapper">
        <div className="card-galleries">
          <img src="https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/hero-galeries-imgs/interio-thum.jpg" className="card-image" alt="Interior" />
          <Link className="galleries-btn" to="/gallery/interior" id="btn-interior">
            Interior
          </Link>
        </div>

        <div className="card-galleries">
          <img src="https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/hero-galeries-imgs/exterior-thum.jpg" className="card-image" alt="Exterior" />
          <Link className="galleries-btn" to="/gallery/exterior" id="btn-exterior">
            Exterior
          </Link>
        </div>

        <div className="card-galleries">
          <img src="https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/hero-galeries-imgs/kitchen-thum.jpg" className="card-image" alt="Kitchen" />
          <Link className="galleries-btn" to="/gallery/kitchen"  id="btn-kitchen">
            Kitchen
          </Link>
        </div>

        <div className="card-galleries">
          <img src="https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/hero-galeries-imgs/wood-thum.jpg" className="card-image" alt="Wooden" />
          <Link className="galleries-btn" to="/gallery/wooden" id="btn-wood">
            Wooden
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Galleries;
