import React, { useContext } from "react";
import { FormContext } from "./context/form-provider";
import { useFormHandlers } from "./hooks/form-handlers";

// Modal component to show after an action is completed
const Modal = ({ showModal, message, formType }) => {
  const { state } = useContext(FormContext);
  const {
    handleUserChoice,
    closeModal,
  } = useFormHandlers();


  if (!showModal) return null;
  // Determine which function to use based on the formType
 

  return (
    <div
      className="modal fade show"
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              className="btn-close"
              aria-label="Close"
              onClick={closeModal}
            ></button>
          </div>
          <div className="modal-body">
            {message} {/* Dynamically display the message prop */}
          </div>
          <div className="modal-footer">
            <button className="btn btn-light" onClick={closeModal}>
              Close
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handleUserChoice("print", formType)}
            >
              Print
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handleUserChoice("email",formType)}
            >
              Email
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
