import React, { useContext, useEffect, Suspense } from "react";
import { FormContext } from "../context/form-provider";
import ProposalForm from "./proposal-form";
import Summary from "../summary";
import Modal from "../modal";
import { useFormHandlers } from "../hooks/form-handlers";
import { fetchData } from "../fetch-data";
import { handleCreateDocument } from "../utils/create-document";
import { useParams, useNavigate } from "react-router-dom";
import ExpireDateInfo from "../expire-date";
import axios from "../../../axios-config";

// Helper function to format date to 'yyyy-MM-dd'
const formatDateForInput = (isoDate) => {
  if (!isoDate) return "";
  return new Date(isoDate).toISOString().split("T")[0];
};

const GenerateProposal = () => {
  const { state, dispatch } = useContext(FormContext);
  const { updateStatus, updateStatusEdit } = useFormHandlers();
  const navigate = useNavigate();
  const { proposalId } = useParams(); // Get proposalId from URL
  const isEditMode = Boolean(proposalId); // Determine if we're editing

  // Fetch service data, Ireland data, and payment methods
  useEffect(() => {
    const loadData = async () => {
      await fetchData("/serviceData.json", dispatch, "SET_SERVICE_DATA");
      await fetchData("/irelandData.json", dispatch, "SET_IRELAND_DATA");

      // Ensure form type is set to "proposal"
      dispatch({
        type: "SET_FORM_TYPE",
        formType: "proposal", // Set formType to "proposal" explicitly
      });

      if (isEditMode) {
        // Fetch existing proposal data if we're editing
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/admin/proposal/${proposalId}`
          );
          const proposalData = response.data;

          // Map the fetched data to the state structure
          dispatch({
            type: "SET_FORM_DATA",
            data: {
              personalInfo: {
                name:
                  proposalData.customer?.name || proposalData.lead?.name || "",
                email:
                  proposalData.customer?.email ||
                  proposalData.lead?.email ||
                  "",
                address01:
                  proposalData.customer?.address01 ||
                  proposalData.lead?.address01 ||
                  "",
                address02:
                  proposalData.customer?.address02 ||
                  proposalData.lead?.address02 ||
                  "",
                street:
                  proposalData.customer?.street ||
                  proposalData.lead?.street ||
                  "",
                eircode:
                  proposalData.customer?.eircode ||
                  proposalData.lead?.eircode ||
                  "",
              },
              // Use formatted date
              selectedCounty: proposalData.selectedCounty || "",
              selectedCity: proposalData.selectedCity || "",
              selectedPaymentMethod: proposalData.selectedPaymentMethod || "",
              selectedSpecificServices:
                proposalData.selectedSpecificServices || [],
              vatPercentage: proposalData.vatPercentage || 0,
              totalBasePrice: proposalData.totalBasePrice || 0,
              vatAmount: proposalData.vatAmount || 0,
              finalTotalPrice: proposalData.finalTotalPrice || 0,
              date: formatDateForInput(proposalData.date) || "",
              expire: formatDateForInput(proposalData.expire) || "",
              status: proposalData.status || "",
            },
          });
        } catch (error) {
          console.error("Error fetching proposal data:", error);
        }
      }
    };

    loadData();
  }, [dispatch, isEditMode, proposalId]);

  const handleCreateProposal = () => {
    dispatch({
      type: "SET_FORM_TYPE",
      formType: "proposal",
    });
    if (isEditMode) {
      // In edit mode, update the proposal without showing the modal
      handleCreateDocument(
        "proposal",
        state,
        dispatch,
        () => {
          alert("Proposal updated successfully!");
          navigate(`/admin/proposal/${proposalId}`); // Redirect to proposal display after update
        },
        (error) => {
          alert("There was an issue updating the proposal. Please try again");
        },
        updateStatusEdit,
        proposalId // Pass the proposalId to update
      );
    } else {
      // If creating a new proposal, show the modal
      dispatch({
        type: "SHOW_MODAL",
        showModal: true,
        modalMessage: "Would you like to print or email the proposal?",
      });

      handleCreateDocument(
        "proposal",
        state,
        dispatch,
        () => {
          alert("Proposal created successfully!");
        },
        (error) => {
          alert("There was an issue saving the proposal. Please try again");
        },
        updateStatus
      );
    }
  };

  return (
    <div id="app-layout">
      <div className="content-page">
        <div className="content">
          <div className="container-xxl">
            <div className="py-3 d-flex align-items-sm-center flex-sm-row flex-column">
              <div className="flex-grow-1">
                <h4 className="fs-18 fw-semibold m-0">
                  {isEditMode ? "Edit Proposal" : "Generate Proposal"}
                </h4>
              </div>
              <div className="text-end">
                <ol className="breadcrumb m-0 py-0">
                  <li className="breadcrumb-item">
                    <button href="#">Pages</button>
                  </li>
                  <li className="breadcrumb-item active">Proposal</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <Suspense fallback={<div>Loading...</div>}>
                <ProposalForm
                  personalInfo={state.personalInfo}
                  date={state.date}
                  selectedCounty={state.selectedCounty}
                  selectedCity={state.selectedCity}
                  priceInput={state.priceInput}
                  specificServices={state.specificServices}
                  errors={state.errors}
                  state={state}
                />
                <Summary />
                <ExpireDateInfo />
              </Suspense>
              <div className="d-print-none">
                <div className="float-end">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleCreateProposal}
                  >
                    {isEditMode ? "Update" : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isEditMode && (
        <Modal
          showModal={state.showModal}
          message={state.modalMessage}
          formType={state.formType}
        />
      )}
    </div>
  );
};

export default GenerateProposal;