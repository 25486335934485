import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';

const Dropdown = ({ title, icon, openDropdown, handleToggle, dropdown, links }) => {
  const handleClick = (event) => {
    event.preventDefault(); // Prevent default behavior
    handleToggle(dropdown); // Call handleToggle function
  };

  return (
    <li>
      {/* Dropdown toggle link */}
      <a href="#" onClick={handleClick} className="d-flex align-items-center justify-content-between">
        <div>
          <i className={icon}></i> {/* Use Bootstrap Icons class */}
          <span> {title} </span>
        </div>
        {/* Use imported Chevron icons conditionally based on dropdown state */}
        {openDropdown === dropdown ? (
          <ChevronUp className="menu-arrow" /> 
        ) : (
          <ChevronDown className="menu-arrow" />
        )}
      </a>
      {/* Dropdown menu */}
      <div className={`collapse ${openDropdown === dropdown ? 'show' : ''}`} id={dropdown}>
        <ul className="nav-second-level">
          {links.map((link, index) => (
            <li key={index}>
              <Link to={link.to}>{link.label}</Link>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

export default Dropdown;