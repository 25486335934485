import { useState } from "react";

export const useAlert = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');

  const showSuccessAlert = (message) => {
    setAlertType('success');
    setAlertMessage(message);
    setShowAlert(true);
  };

  const showErrorAlert = (message) => {
    setAlertType('danger');
    setAlertMessage(message);
    setShowAlert(true);
  };

  const closeAlert = () => setShowAlert(false);

  return {
    showAlert,
    alertType,
    alertMessage,
    showSuccessAlert,
    showErrorAlert,
    closeAlert,
  };
};