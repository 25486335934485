import { useEffect } from "react";
import axiosInstance from "../../../axios-config";
import {
  useInvoiceTableContext,
  useProposalTableContext,
  useCustomerTableContext,
  useLeadTableContext,
  useEmployeeTableContext,
  useUserTableContext,
} from "../context/table-context";

export const useTableData = (tableType) => {
  // Call both hooks unconditionally
  const invoiceContext = useInvoiceTableContext();
  const proposalContext = useProposalTableContext();
  const customerContext = useCustomerTableContext(); // For Customer table
  const leadContext = useLeadTableContext(); // For Lead table
  const employeeContext = useEmployeeTableContext(); // For Lead table
  const userContext = useUserTableContext(); // For Lead table

  // Choose the correct context based on tableType
  const context =
    tableType === "invoices"
      ? invoiceContext
      : tableType === "proposals"
      ? proposalContext
      : tableType === "employees"
      ? employeeContext
      : tableType === "users"
      ? userContext
      : tableType === "customers"
      ? customerContext
      : leadContext; // Add logic for customer and lead context

  if (!context) {
    console.error(
      "Context is not defined! Check if Provider is wrapping the component."
    );
  }

  const { state, dispatch } = context;

  // Log tableType to make sure it's correct
  console.log("TableType in useTableData hook:", tableType);
  console.log(`${tableType} Context State:`, state);

  // Define API URLs based on the table type
  const apiUrl =
    tableType === "invoices"
      ? `${process.env.REACT_APP_API_URL}/api/admin/invoices`
      : tableType === "proposals"
      ? `${process.env.REACT_APP_API_URL}/api/admin/proposals`
      : tableType === "employees"
      ? `${process.env.REACT_APP_API_URL}/api/admin/employees`
      : tableType === "users"
      ? `/api/auth/users`
      : tableType === "customers"
      ? `${process.env.REACT_APP_API_URL}/api/admin/customers`
      : `${process.env.REACT_APP_API_URL}/api/admin/leads`; // Add URL for Customers and Leads

  const searchApiUrl =
    tableType === "invoices"
      ? `${process.env.REACT_APP_API_URL}/api/admin/invoices/search`
      : tableType === "proposals"
      ? `${process.env.REACT_APP_API_URL}/api/admin/proposals/search`
      : tableType === "employees"
      ? `${process.env.REACT_APP_API_URL}/api/admin/employees/search`
      : tableType === "users"
      ? `${process.env.REACT_APP_API_URL}/api/admin/users/search`
      : tableType === "customers"
      ? `${process.env.REACT_APP_API_URL}/api/admin/customers/search`
      : `${process.env.REACT_APP_API_URL}/api/admin/leads/search`; // Add search URL for Customers and Leads

  // Fetch data and dispatch to reducer
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(apiUrl);
        const fetchedData = response.data;
        console.log("test dispatch", response.data);
        dispatch({ type: "SET_DATA", payload: response.data[tableType] });
        dispatch({
          type: "SET_FILTERED_DATA",
          tableType: tableType,
          payload: response.data,
        });
      } catch (error) {
        console.error(`Error fetching ${tableType} data:`, error);
      }
    };

    fetchData();
  }, [dispatch, apiUrl, tableType]);

// Filter data based on search criteria
useEffect(() => {
  const { searchQuery = "", dateRange = [null, null], statusFilter = "" } = state.tableState[tableType] || {};

  if (searchQuery || (dateRange?.[0] && dateRange?.[1]) || statusFilter) {
    const fetchFilteredData = async () => {
      console.log(`Filtering ${tableType} data with:`, {
        startDate: dateRange?.[0] ? dateRange[0].toISOString() : undefined,
        endDate: dateRange?.[1] ? dateRange[1].toISOString() : undefined,
        customerName: searchQuery || undefined,
        status: statusFilter || undefined,
      });

      try {
        const response = await axiosInstance.get(searchApiUrl, {
          params: {
            startDate: dateRange?.[0] ? dateRange[0].toISOString() : undefined,
            endDate: dateRange?.[1] ? dateRange[1].toISOString() : undefined,
            customerName: searchQuery || undefined,
            status: statusFilter || undefined,
          },
        });
        console.log(`Filtered ${tableType} data:`, response.data);
        dispatch({
          type: "SET_FILTERED_DATA",
          tableType: tableType,
          payload: response.data,
        });
      } catch (error) {
        console.error(`Error fetching filtered ${tableType} data:`, error);
      }
    };

    fetchFilteredData();
  } else {
    dispatch({
      type: "SET_FILTERED_DATA",
      tableType: tableType,
      payload: state.data,
    });
  }
}, [
  state.tableState[tableType]?.searchQuery,
  state.tableState[tableType]?.dateRange,
  state.tableState[tableType]?.statusFilter,
  state.data,
  dispatch,
  searchApiUrl,
  tableType,
]);

  // Handle Status Change Logic
  const handleActionClick = async (item, newStatus) => {
    const apiUrl =
      tableType === "invoices"
        ? `${process.env.REACT_APP_API_URL}/api/admin/invoices/${item._id}`
        : `${process.env.REACT_APP_API_URL}/api/admin/proposals/${item._id}`;

    try {
      await axiosInstance.put(apiUrl, { status: newStatus });
      const updatedData = state.filteredData.map((dataItem) =>
        dataItem._id === item._id
          ? { ...dataItem, status: newStatus }
          : dataItem
      );
      dispatch({ type: "SET_FILTERED_DATA", payload: updatedData });
    } catch (error) {
      console.error(`Error updating ${tableType} status:`, error);
    }
  };

// Handle Delete Logic
 const handleDeleteClick = async (id) => {
  let deleteUrl = "";
  
  switch (tableType) {
    case "invoices":
      deleteUrl = `${process.env.REACT_APP_API_URL}/api/admin/invoices/${id}`;
      break;
    case "proposals":
      deleteUrl = `${process.env.REACT_APP_API_URL}/api/admin/proposals/${id}`;
      break;
    case "users":
      deleteUrl = `/api/auth/users/${id}`;
      break;
    case "employee":
      deleteUrl = `${process.env.REACT_APP_API_URL}/api/admin/employee/${id}`;
      break;
    case "customers":
      deleteUrl = `${process.env.REACT_APP_API_URL}/api/admin/customers/${id}`;
      break;
    case "leads":
    default:
      deleteUrl = `${process.env.REACT_APP_API_URL}/api/admin/leads/${id}`;
      break;
  }

  try {
    await axiosInstance.delete(deleteUrl);

    // Safeguard and update the correct data based on table type
    if (Array.isArray(state.filteredData[tableType])) {
      const updatedData = state.filteredData[tableType].filter(
        (dataItem) => dataItem._id !== id
      );
      dispatch({
        type: "SET_FILTERED_DATA",
        tableType, // Ensure we update the right table
        payload: updatedData,
      });
    } else {
      console.error(`Filtered data for ${tableType} is not an array`, state.filteredData[tableType]);
    }
  } catch (error) {
    console.error(`Error deleting ${tableType}:`, error);
  }
};

  return { handleActionClick, handleDeleteClick };
};


