import React, { useContext, useEffect, useState } from "react";
import { fetchData } from "./fetch-data";
import { FormContext } from "./context/form-provider";
import { useFormHandlers } from "./hooks/form-handlers";
import DynamicFormField from "./dynamic-form-field";
import locationFormConfig from "././json/location-form-config.json"; // Import the JSON config

const LocationForm = () => {
  const { state, dispatch } = useContext(FormContext);
  const {
    handleCountyChange,
    handleCityChange,
  } = useFormHandlers();
  const errors = state.errors || {};

  useEffect(() => {
    fetchData("/irelandData.json", dispatch, "SET_IRELAND_DATA");
  }, [dispatch]);

  return (
    <>
      {locationFormConfig.fields.map((field) => (
        <DynamicFormField
          key={field.name}
          field={field}
          value={state[field.name === "selectedCounty" ? "selectedCounty" : "selectedCity"]}
          onChange={field.name === "selectedCounty" ? handleCountyChange : handleCityChange}
          options={field.name === "selectedCounty" ? Object.keys(state.irelandData) : state.cities}
          errors={errors}
        />
      ))}
    </>
  );
};

export default LocationForm;
