import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrush, faCloudSun, faHandSparkles, faScroll } from "@fortawesome/free-solid-svg-icons";
import "./key-service-description.styles.css";

const KeyServiceDescription = () => {
    return (
      <div className="key-description-services">
      <div className="icon-wrapper">
        <div className="sub-icon-services">
          <FontAwesomeIcon icon={faScroll} className="icon" />
          <h1>Wallpaper Hanging</h1>
          <p>
            From residential spaces to commercial projects, we cater to every
            need and style preference. Our seamless process ensures a
            hassle-free experience, from initial consultation to final
            installation, leaving you with stunning walls that make a lasting
            impression.
          </p>
        </div>
        <div className="sub-icon-services">
          <FontAwesomeIcon icon={faCloudSun} className="icon" />
          <h1>Indoor & Outdoor Painting</h1>
          <p>
            Whether it's a cozy living room, a sleek office space, or the
            exterior facade of your property, we approach each project with
            dedication and professionalism. With our personalized service and
            tailored solutions, we work closely with you to bring your vision
            to life, transforming your space into a reflection of your unique
            style and personality.
          </p>
        </div>
        <div className="sub-icon-services">
          <FontAwesomeIcon icon={faBrush} className="icon" />
          <h1>Coats & Restoration</h1>
          <p>
            Whether it's a vintage home, a heritage building, or a beloved
            family heirloom, we have the expertise and experience to bring out
            its full potential. Our tailored solutions are designed to meet
            your unique needs, ensuring a seamless process from start to
            finish.
          </p>
        </div>
        <div className="sub-icon-services">
          <FontAwesomeIcon icon={faHandSparkles} className="icon" />
          <h1>Hand Painted Kitchen Cabinets</h1>
          <p>
            With our hand-painted kitchen cabinets services, you can elevate
            the aesthetics of your kitchen while preserving its functionality
            and value. Our personalized approach ensures that every detail is
            tailored to your taste and lifestyle, creating a space that is as
            unique as you are.
          </p>
        </div>
      </div>
    </div>
    )
}

export default KeyServiceDescription;