import React from 'react';
import PropTypes from 'prop-types';

// Reusable Alert Component
const AlertMessage = ({ type, message, showAlert, onClose }) => {
  if (!showAlert) return null; // Do not render if showAlert is false

  // Define alert classes dynamically based on the type prop
  const alertClass = `alert alert-${type} role="alert"`;

  return (
    <div className="col-xl-12">

        {/* end card header */}
        <div className="card-body">
          <div className={alertClass}>
            {message}
            {/* Close button */}
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        {/* end card body */}

      {/* end card */}
    </div>
  );
};

// Define prop types for type checking
AlertMessage.propTypes = {
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark'
  ]).isRequired,
  message: PropTypes.string.isRequired,
  showAlert: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AlertMessage;