// handle-create-form.js
import axios from "../../../axios-config";
import { validateForm } from "./form-validation";


export const handleCreateDocument = async (
  formType,
  state,
  dispatch,
  successCallback,
  errorCallback,
  updateStatusEdit, // Updated to match the function name
  documentId // Added documentId parameter
) => {
    // Determine if we are in edit mode based on the presence of a documentId
 
  // Validate the form fields
  const filteredErrors = validateForm(state);

  if (Object.keys(filteredErrors).length > 0) {
    dispatch({ type: "SET_ERRORS", errors: filteredErrors });
    return;
  }

  try {
    let response;
    let savedDocument; // Variable to store the saved/updated document

    if (documentId) {
      // Update existing document
      response = await axios.put(
        `/api/admin/${formType}/${documentId}`,
        state,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      savedDocument = response.data.updatedProposal || response.data.updatedInvoice;
    } else {
      // Create new document
      response = await axios.post(
        `/api/admin/create-${formType}`,
        state,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      savedDocument = response.data.savedProposal || response.data.savedInvoice;
    }
    

    const documentName = formType.charAt(0).toUpperCase() + formType.slice(1);
    const action = documentId ? "updated" : "created";
    console.log(`${documentName} successfully ${action}`, response.data);

    // Make sure _id is present in response data
    const savedOrUpdatedDocument = response.data.savedProposal || response.data.updatedProposal || response.data.savedInvoice || response.data.updatedInvoice;
    if (!savedOrUpdatedDocument || !savedOrUpdatedDocument._id) {
      throw new Error(`${documentName} data is invalid or missing _id`);
    }

    // Trigger success callback if provided
    if (successCallback) successCallback(response);


    // Handle different document types dynamically
    switch (formType) {
      case "invoice":
        await handleInvoiceCreation(
          savedDocument,
          state,
          dispatch,
          updateStatusEdit,
          documentId // Pass documentId to indicate update
        );
        break;
      case "proposal":
        await handleEstimateCreation(
          savedDocument,
          state,
          dispatch,
          updateStatusEdit, // Pass the correct function
          documentId // Pass documentId to indicate update
        );
        break;
      default:
        console.warn("Unknown document type:", formType);
    }

    // Clear form and errors after successful submission
    dispatch({ type: "TOGGLE_MODAL" });
    dispatch({ type: "CLEAR_ERRORS" });
    dispatch({ type: "CLEAR_ALL_FIELDS" });
  } catch (error) {
    console.error(`Error creating or updating ${formType}:`, error.message);
    console.error("Error stack:", error.stack);
    if (errorCallback) errorCallback(error);
  }
};
// Handle invoice creation specific logic
const handleInvoiceCreation = async (
  invoice,
  state,
  dispatch,
  updateStatusEdit,
  documentId // Use documentId to determine if it's an update
) => {
  if (!invoice || !invoice._id) {
    console.error("Invalid invoice data:", invoice);
    throw new Error("Invoice data is invalid or missing");
  }

  dispatch({ type: "SET_INVOICE", data: invoice });
  console.log(`Invoice ${documentId ? "updated" : "created"} and set in state`, invoice);

  const status = state.status; // Default to "created" if status is not set
  const invoiceId = invoice._id; // Extract invoice ID from the response

  try {
    // Call the function to update the invoice status
    if (updateStatusEdit && typeof updateStatusEdit === "function") {
      await updateStatusEdit("invoice", invoiceId, status);
    } else {
      console.error("updateStatusEdit is not a function");
    }
  } catch (error) {
    console.error("Error updating invoice status:", error);
  }
};

const handleEstimateCreation = async (
  proposal,
  state,
  dispatch,
  updateStatusEdit,
  documentId
) => {
  if (!proposal || !proposal._id) {
    console.error("Invalid proposal data:", proposal);
    throw new Error("Proposal data is invalid or missing _id");
  }

  // Set the proposal in the state
  dispatch({ type: "SET_PROPOSAL", data: proposal });
  console.log(`Proposal ${documentId ? "updated" : "created"} and set in state`, proposal);

  const status = state.status; // Default to "created" if status is not set
  const proposalId = proposal._id; // Extract proposal ID from the response

  try {
    // Call the function to update the proposal status
    if (updateStatusEdit && typeof updateStatusEdit === "function") {
      await updateStatusEdit("proposal", proposalId, status);
    } else {
      console.error("updateStatusEdit is not a function");
    }
  } catch (error) {
    console.error("Error updating proposal status:", error);
  }
};