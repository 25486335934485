import React from "react";

const ProfileHeader = ({ documentType, documentData, config }) => {
  // Get the configuration for the current document type
  const documentConfig = config[documentType];

  if (!documentConfig) {
    return <div>No configuration available for this document type</div>;
    
  }
    // Log the configuration for debugging
    console.log("Document Config:", documentConfig);

    const { fields} = documentConfig;

  // Helper function to safely access the fields in documentData
  const getFieldValue = (fieldKey) => documentData[fields[fieldKey]] || "N/A";



  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="align-items-center">
              <div className="d-flex align-items-center">
                <div className="overflow-hidden ms-4">
                  {/* Display name fields dynamically based on document type */}
                  <h4 className="m-0 text-dark fs-20">
                    {documentType === "User" || documentType === "employee"
                      ? `${getFieldValue("firstname")} ${getFieldValue("lastname")}`
                      : getFieldValue("name")}
                  </h4>

                  {/* Display dynamic role or position if available */}
                  {documentType === "employee" && (
                    <p className="my-1 text-muted fs-16">
                      {getFieldValue("employeePosition")}
                    </p>
                  )}
                  {documentType === "user" && (
                    <p className="my-1 text-muted fs-16">
                      {getFieldValue("role")}
                    </p>
                  )}

                  {/* Display other fields such as email for Lead or Customer */}
                  {(documentType === "lead" || documentType === "customer") && (
                    <p className="my-1 text-muted fs-16">
                      {getFieldValue("email")}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;