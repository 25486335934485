import React, { useEffect, useRef } from 'react';
import './our-customers.styles.css';

const OurCustomers = () => {
  const carousel = [
    "https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/external+logos/jp%26m.jpg",
    "https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/external+logos/tm_blinds_limited_logo.jpeg",
    "https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/external+logos/HEATWAVE-EXTENSIONS-LOGO.webp",
    "https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/external+logos/m%26k.png",
    "https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/external+logos/life-pharmacy.png"
  ];

  const carouselRef = useRef(null);

  useEffect(() => {
    const carouselElement = carouselRef.current;
    if (!carouselElement) return;
    let animationId;
    const duration = 20;

    const animate = () => {
      if (carouselElement) {
        carouselElement.style.transition = `transform ${duration}s linear`;
        carouselElement.style.transform = `translateX(-50%)`;
      }

      animationId = setTimeout(() => {
        if (carouselElement) {
          carouselElement.style.transition = 'none';
          carouselElement.style.transform = 'translateX(0)';
          // const reflow = carouselElement.offsetHeight; // Trigger a reflow to reset the animation
          animate();
        }
      }, duration * 1000);
    };

    // Use requestAnimationFrame to ensure the DOM has fully rendered
    requestAnimationFrame(animate);

    return () => {
      clearTimeout(animationId);
    };
  }, []);

  return (
    <section className="customer-section">
      <div className="section-header">
        <h1 className="section-heading">Our Customers</h1>
        <div className="underline"></div>
      </div>
      <div className="external-contact-wrapper">
        <div className="carousel-container-clients" id="img-client">
          <div className="carousel-clients" ref={carouselRef}>
            {carousel.map((src, index) => (
              <img src={src} alt={`Client ${index + 1}`} key={index} className="carousel-image" />
            ))}
            {carousel.map((src, index) => (
              <img src={src} alt={`Client ${index + 1}`} key={`duplicate-${index}`} className="carousel-image" />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurCustomers;
