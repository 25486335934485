import React from 'react';

import './testimonial.styles.css';

const Testimonial = () => {
  return (
    <section className="stories">
    <div className="video-container">
      <video className="bg-video" autoPlay muted loop playsInline>
        <source src="https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/video/landscape.mp4" type="video/mp4" />
      </video>
    </div>
    <div className="stories-wrapper">
      <div className="story-bg">
        <div className="story">
          <img
            src="https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/external+logos/kill-pharmacy.jpg"
            alt="Kill Phamacy logo"
            className="story-image"
          />
          <div className="story-text">
            <h1 className="story-heading">
              Say goodbye to damage walls, hello to a stylish business
            </h1>
            <p className="story-paragraph">
              Thanks so much to Magic Rainbow for making Kill Pharmacy look
              so shiny and new! Such a great, professional and hardworking
              team. Highly recommend them.
            </p>
          </div>
        </div>
      </div>
      <div className="story-bg">
        <div className="story">
          <img src="" alt="Customer portrait" className="story-image" />
          <div className="story-text">
            <h1 className="story-heading">I enjoyed this great tour</h1>
            <p className="story-paragraph">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Architecto quas, repudiandae veritatis nam mollitia cumque
              distinctio, quia aperiam aliquid at consequuntur libero
              quisquam facilis laborum inventore repellat perspiciatis vel
              fugiat molestias recusandae eum necessitatibus quo possimus
              aspernatur? Nobis, architecto eaque.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Testimonial;