import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faMedal, faBrush } from "@fortawesome/free-solid-svg-icons";
import "./service-description.styles.css";

const ServicesDescription = () => {
    return (
      <div className="service-discription">
      <div className="icon-wrapper">
        <div className="icon-services">
        <FontAwesomeIcon icon={faClock} className="icon" />
          <h1>Fast Service</h1>
          <p>
            We understand the importance of time and efficiency. That's why we
            take pride in offering fast and reliable painting and decorating
            services to meet your deadlines and exceed your expectations.
          </p>
        </div>
        <div className="icon-services">
        <FontAwesomeIcon icon={faMedal} className="icon" />
          <h1>Quality Guaranteed</h1>
          <p>
            We stand behind the quality of our workmanship. With our
            commitment to excellence and attention to detail, we guarantee a
            result that exceeds your expectations.
          </p>
        </div>
        <div className="icon-services">
        <FontAwesomeIcon icon={faBrush} className="icon" />
          <h1>Experienced Team</h1>
          <p>
            Our success is built on the foundation of our highly skilled and
            experienced team of painters and decorators. With years of
            collective expertise, we are dedicated to delivering exceptional
            results.
          </p>
        </div>
      </div>
    </div>
    )
}

export default ServicesDescription;