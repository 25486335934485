import React, { createContext, useReducer, useContext } from "react";
import { tableReducer, initialState } from "../hooks/table-reducer";

// Create separate contexts for Invoice, Proposal, Customer, and Lead tables
const InvoiceTableContext = createContext();
const ProposalTableContext = createContext();
const CustomerTableContext = createContext();
const LeadTableContext = createContext();
const EmployeeTableContext = createContext();
const UserTableContext = createContext();

// Hooks to access the contexts
export const useInvoiceTableContext = () => useContext(InvoiceTableContext);
export const useProposalTableContext = () => useContext(ProposalTableContext);
export const useCustomerTableContext = () => useContext(CustomerTableContext);
export const useLeadTableContext = () => useContext(LeadTableContext);
export const useEmployeeTableContext = () => useContext( EmployeeTableContext);
export const useUserTableContext = () => useContext(UserTableContext);

// InvoiceTableProvider to manage state for Invoice Table
export const InvoiceTableProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tableReducer, initialState);
  console.log("Invoice Table Context State:", state); // Log state
  return (
    <InvoiceTableContext.Provider value={{ state, dispatch }}>
      {children}
    </InvoiceTableContext.Provider>
  );
};

// ProposalTableProvider to manage state for Business Proposal Table
export const ProposalTableProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tableReducer, initialState);
  console.log("Proposal Table Context State:", state); // Log state
  return (
    <ProposalTableContext.Provider value={{ state, dispatch }}>
      {children}
    </ProposalTableContext.Provider>
  );
};

// CustomerTableProvider to manage state for Customer Table
export const CustomerTableProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tableReducer, initialState);
  console.log("Customer Table Context State:", state); // Log state
  return (
    <CustomerTableContext.Provider value={{ state, dispatch }}>
      {children}
    </CustomerTableContext.Provider>
  );
};

// LeadTableProvider to manage state for Lead Table
export const LeadTableProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tableReducer, initialState);
  console.log("Lead Table Context State:", state); // Log state
  return (
    <LeadTableContext.Provider value={{ state, dispatch }}>
      {children}
    </LeadTableContext.Provider>
  );
};
// LeadTableProvider to manage state for Lead Table
export const EmployeeTableProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tableReducer, initialState);
  console.log("Employee Table Context State:", state); // Log state
  return (
    <EmployeeTableContext.Provider value={{ state, dispatch }}>
      {children}
    </EmployeeTableContext.Provider>
  );
};
// LeadTableProvider to manage state for Lead Table
export const UserTableProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tableReducer, initialState);
  console.log("User Table Context State:", state); // Log state
  return (
    <UserTableContext.Provider value={{ state, dispatch }}>
      {children}
    </UserTableContext.Provider>
  );
};