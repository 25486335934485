// AdminLayout.jsx

import React from "react";
import { Outlet } from "react-router-dom"; // Use Outlet for nested routes
import Sidebar from "./sidebar.component/sidebar-layout";

import Topbar from "./dashboard.component/topbar.component/topbar";
import "../scss/App.scss"

const AdminLayout = () => {
    return (
      <div>
        <Topbar  />
        <Sidebar />
        <div className="admin-content">
          <Outlet /> {/* This renders the nested admin routes */}
        </div>
      </div>
    );
  };

export default AdminLayout;