// src/form.reusable.component/form.js
import React, { useState } from 'react';

const ReusableForm = ({ fields, onSubmit, children }) => {
  const [formData, setFormData] = useState(
    fields.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {})
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      {fields.map((field) => (
        <div className="mb-3" key={field.name}>
          <label>{field.label}</label>
          <input
            type={field.type}
            name={field.name}
            value={formData[field.name]}
            placeholder={field.placeholder}
            className={field.className}
            onChange={handleChange}
          />
        </div>
      ))}
      {children}
    </form>
  );
};

export default ReusableForm;