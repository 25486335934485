import React from 'react'
import DynamicFormField from '../../dynamic-form-field'

// Text input field component
export const TextInputField = ({ field, value, onChange, errors }) => (
    <div className="col-md-6 position-relative">
      <DynamicFormField
        field={field}
        value={value || ''} // Ensure controlled input
        onChange={onChange}
        errors={errors}
      />
    </div>
  );
  
  // Textarea field component
export  const TextAreaField = ({ field, value, onChange, errors }) => (
    <div className="col-md-6 position-relative">
      <DynamicFormField
        field={field}
        value={value || ''} // Ensure controlled input
        onChange={onChange}
        errors={errors}
      />
    </div>
  );
  
  // Checkbox field component
export  const CheckboxField = ({ field, value, onChange, errors }) => (
    <div className="col-md-6 position-relative">
      <DynamicFormField
        field={field}
        value={value || false} // Ensure controlled input
        onChange={onChange}
        errors={errors}
      />
    </div>
  );
  
  // Radio field component
export const RadioField = ({ field, value, onChange, options, errors }) => (
    <div className="col-md-6 position-relative">
      <DynamicFormField
        field={field}
        value={value || ''} // Ensure controlled input
        onChange={onChange}
        options={options || []}
        errors={errors}
      />
    </div>
  );
  
  // Select field component
export  const SelectField = ({ field, value, onChange, options, errors }) => (
    <div className="col-md-6 position-relative">
      <DynamicFormField
        field={field}
        value={value || ''} // Ensure controlled input
        onChange={onChange}
        options={options || []}
        errors={errors}
      />
    </div>
  );
  
  // Date field component
export  const DateField = ({ field, value, onChange, errors }) => (
    <div className="col-md-6 position-relative">
      <DynamicFormField
        field={field}
        value={value || ''} // Ensure controlled input
        onChange={onChange}
        errors={errors}
      />
    </div>
  );
  
  // File input field component
export  const FileInputField = ({ field, onChange, errors }) => (
    <div className="col-md-6 position-relative">
      <DynamicFormField
        field={field}
        onChange={onChange}
        errors={errors}
      />
    </div>
  );