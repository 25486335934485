import { Link } from "react-router-dom";
import ActionButtons from "./action-btn"; // Assuming ActionButtons is imported

// Mapping object to associate table types with their routes and components
const routeMapping = {
  invoices: { type: "invoice", route: "document" },  // Use 'document' for invoice types
  proposals: { type: "proposal", route: "document" }, // Use 'document' for proposal types
  leads: { type: "lead", route: "profile" }, // Use 'document' for proposal types
  employees: { type: "employee", route: "profile" },  // Use 'profile' for employee types
  customers: { type: "customer", route: "profile" },  // Use 'profile' for customer types
  users: { type: "user", route: "profile" },          // Use 'profile' for user types
};

export const getStatusClass = (status) => {
  switch (status) {
    case "Created":
      return "bg-light";
    case "Emailed":
      return "bg-info";
    case "Printed":
      return "bg-primary";
    case "Payment Received":
      return "bg-success";
    case "Due for Payment":
      return "bg-warning";
    default:
      return "bg-dark";
  }
};



export const renderCell = (item, column, handleActionClick, handleDeleteClick, tableType) => {
  // Extract nested keys for dynamic properties like "customer.name"
  const keys = column.key.split(".");
  const value = keys.reduce((obj, key) => (obj ? obj[key] : null), item);

   // Determine if the base route should be `/auth` for users or `/admin` for others
 const baseRoute = tableType ===  "/admin";

  // Handle ID column and render as a link to the document or profile
  if (column.key === "_id") {
    const routeConfig = routeMapping[tableType];
    const documentType = routeConfig?.type || "document"; // Get type from routeMapping, fallback to 'document'
    const route = routeConfig?.route || "document"; // Get route from routeMapping

    // Use the correct route based on the type and route
    return (
      <Link to={`/admin/${route}/${documentType}/${value}`} style={{ textDecoration: "none" }}>
        {value}
      </Link>
    );
  }

  // Custom logic for proposals to display either customer or lead name
  if (tableType === "proposals" && column.key === "client") {
    const clientName = item.customer?.name || item.lead?.name || "N/A";
    return clientName;
  }

  // Handle different column types like date, currency, and status
  switch (column.type) {
    case "date":
      return value ? new Date(value).toLocaleDateString() : "N/A";
    case "currency":
      return value ? `${value.toFixed(2)} €` : "N/A";
    case "status":
      return (
        <span className={`badge ${getStatusClass(value)}`}>
          {value || "N/A"}
        </span>
      );
    
    // Handle actions with dropdown
    case "actions":
      return (
        <ActionButtons
          item={item}
          tableType={tableType}
          handleActionClick={handleActionClick}
          handleDeleteClick={handleDeleteClick}
        />
      );
      
    default:
      return value || "N/A";
  }
};