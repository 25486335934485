// src/components/DocumentTable.jsx

import React from 'react';

const DocumentTable = ({ fields, itemsKey, documentData }) => {
 // Determine the items array from documentData
 const items = documentData[itemsKey] || [];

 // Function to format values based on the 'format' property
 const formatValue = (value, format) => {
   if (format === 'decimal') {
     // Ensure value is a number and format to two decimal places
     const number = parseFloat(value);
     return isNaN(number) ? value : number.toFixed(2);
   }
   // Add more formatting options if needed
   return value;
 };

 return (
  <div className="row">
    <div className="col-md-12">
      <div className="table-responsive">
        <table className="table mt-4 mb-4 table-centered table-hover table-borderless">
          <thead >
            <tr>
              {fields.map((field) => (
                <th key={field.key}>{field.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                {fields.map((field) => (
                  <td  key={field.key}>
                    {formatValue(item[field.key], field.format)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);
};

export default DocumentTable;