
import registerEmployeeConfig from "../json/employee-registraction-form-config.json"
import registerUserConfig from "../json/user-registration-form-config.json"

// Function to combine all form configurations
export const loadFormConfigs = () => {
    // Return the appropriate form configuration based on the form type
    return registerEmployeeConfig;
};

// Function to combine all form configurations
export const loadUserFormConfigs = () => {
    // Return the appropriate form configuration based on the form type
    return registerUserConfig;
};

/**
 * The issue you’re encountering, where only the last JSON file’s fields are being displayed,
 * is likely due to how the formConfig is being merged or combined.
 * When you combine multiple JSON configurations,
 * if they are not properly merged, each new configuration might overwrite the previous one in the same object, rather than combining them into a single array.
 */
