import React, { useContext, useEffect } from 'react'
import { FormContext } from './context/form-provider'
import { useFormHandlers } from './hooks/form-handlers';
import { fetchData } from './fetch-data';
import DynamicFormField from './dynamic-form-field';
import paymentMethodFormConfig from './json/payment-form-config.json'

// Payment component to handle the selection of payment methods dynamically
const Payment = () => {
    const { state, dispatch } = useContext(FormContext);
    const {handlePaymentChange } = useFormHandlers()
    const errors = state.errors || {};

    // Fetch payment methods data when component mounts
    useEffect(() => {
        fetchData('/payMethodData.json', dispatch,"SET_PAYMENT_INFO_DATA" )
      }, [dispatch])


    return(
        <div className="col-lg-6 mt-3">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title mb-0">Payment Method</h5>
          </div>
          <div className="card-body">
          {paymentMethodFormConfig.fields.map((field) => (
                <DynamicFormField
                    key={field.name}
                    field={field}
                    value={state[field.name]}
                    onChange={handlePaymentChange}
                    options={Object.keys(state.paymentInfoData)}  // Dynamically pass payment options
                    errors={errors}
                />
            ))}
          </div>
        </div>
      </div>
    )
}

export default Payment;