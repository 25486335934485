import React from "react";
import { Tab, Nav } from "react-bootstrap";
import ContactDetails from "./contact";
import ExperienceSection from "./information";
import EducationSection from "./expertise-section";


const customTabStyles = {
    backgroundColor: "transparent",
    color: "#007bff",
    borderBottom: "2px solid #007bff",
    fontWeight: "bold",
  };
  
  const inactiveTabStyles = {
    backgroundColor: "transparent",
    color: "#333",
    borderBottom: "2px solid transparent",
  };
  
const ProfileTabs = ({ documentType, documentData, config }) => {
  // Capitalize the documentType
  const DocumentType = documentType
  const documentConfig = config[DocumentType];

  if (!documentConfig) {
    return <div>No configuration available for this document type</div>;
  }

  const { sections } = documentConfig;

  return (
    <Tab.Container defaultActiveKey="about">
      <Nav variant="pills" className="nav-underline border-bottom">
        {/* Dynamically create a tab for each section */}
        {Object.keys(sections).map((sectionKey) => (
          <Nav.Item key={sectionKey} >
            <Nav.Link eventKey={sectionKey} className="p-2" style={sectionKey === "active" ? customTabStyles : inactiveTabStyles}>
              <span className="d-none d-sm-block">{sections[sectionKey].label}</span>
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>

      <Tab.Content className="tab-content text-muted bg-white pt-4">
        {/* Dynamically render the content for each section */}
        {Object.keys(sections).map((sectionKey) => (
          <Tab.Pane eventKey={sectionKey} key={sectionKey}>
            <div className="row">
              <div className="col-md-6 mb-4">
                {/* Use different components based on section type */}
                {sectionKey === "about" && (
                  <ContactDetails
                    documentType={documentType}
                    documentData={documentData}
                    config={config}
                  />
                )}

                {/* You can add more cases for different sections */}
                {sectionKey === "contactDetails" && (
                  <ContactDetails
                    documentType={documentType}
                    documentData={documentData}
                    config={config}
                  />
                )}

                {sectionKey === "experience" && (
                  <ExperienceSection
                    documentType={documentType}
                    documentData={documentData}
                    config={config}
                  />
                )}

                {sectionKey === "education" && (
                  <EducationSection
                    documentType={documentType}
                    documentData={documentData}
                    config={config}
                  />
                )}

                {/* Additional Information Section */}
                {sectionKey === "additionalInfo" && (
                  <div>
                    {sections.additionalInfo.optionalFields.map((optionalKey) => {
                      const fieldValue = documentData[documentConfig.optionalFields[optionalKey]];
                      return fieldValue ? (
                        <div key={optionalKey} className="mb-2">
                          <strong>{optionalKey}: </strong>
                          {fieldValue}
                        </div>
                      ) : null;
                    })}
                  </div>
                )}
              </div>
            </div>
          </Tab.Pane>
        ))}
      </Tab.Content>
    </Tab.Container>
  );
};

export default ProfileTabs;