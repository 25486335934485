import React, { useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "./dropdown";
import "bootstrap-icons/font/bootstrap-icons.css"; // Import Bootstrap Icons CSS
import { sidebarConfig } from "./sidebar-config";
// index.js or App.js
import 'bootstrap-icons/font/bootstrap-icons.css';

const Sidebar = () => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleToggle = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  // Render a single sidebar item
  const renderSidebarItem = (item) => {
    if (item.type === "menu-title") {
      return <li className="menu-title">{item.title}</li>;
    }

    if (item.dropdown) {
      return (
        <Dropdown
          title={item.title}
          icon={item.icon}
          openDropdown={openDropdown}
          handleToggle={handleToggle}
          dropdown={item.title}
          links={item.links}
        />
      );
    }

    return item.external ? (
      <li key={item.title}>
        <a href={item.path} target="_blank" rel="noopener noreferrer" className="sidebar-link">
          <i className={`${item.icon} sidebar-icon`}></i>
          <span>{item.title}</span>
        </a>
      </li>
    ) : (
      <li key={item.title}>
        <Link to={item.path} className="sidebar-link">
          <i className={`${item.icon} sidebar-icon`}></i>
          {item.badge && <span className={`badge ${item.badge.className}`}>{item.badge.text}</span>}
          <span>{item.title}</span>
        </Link>
      </li>
    );
  };

  return (
    <div className="app-sidebar-menu">
      <div className="h-100" data-simplebar>
        {/* Sidebar Menu */}
        <div id="sidebar-menu">
          {/* Logo Section */}
          <div className="logo-box">
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src="assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src="assets/images/logo-light.png" alt="" height="24" />
              </span>
            </Link>
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src="assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img
                  src="https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/logo/Logo.png"
                  alt=""
                  height="60"
                />
              </span>
            </Link>
          </div>
          {/* Sidebar Items */}
          <ul id="side-menu">
            {sidebarConfig.map((item) => renderSidebarItem(item))}
          </ul>
        </div>
        {/* End Sidebar */}
        <div className="clearfix"></div>
      </div>
    </div>
  );
};

export default Sidebar;