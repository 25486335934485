import React from 'react';
import PropTypes from 'prop-types';

const PageLayout = ({ children, title }) => {
  React.useEffect(() => {
    document.title = title; // Set the page title for SEO
  }, [title]);

  return (
    <div className="page-layout">
      <header className="page-header">
        {/* Add a global header here if needed */}
      </header>
      <main className="page-content">
        {children}
      </main>
      <footer className="page-footer">
        {/* Add a global footer here if needed */}
      </footer>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default PageLayout;
