import React from "react";
import { useParams } from "react-router-dom";
import Table from "./table-component";
import {
  InvoiceTableProvider,
  ProposalTableProvider,
  CustomerTableProvider,
  LeadTableProvider,
  EmployeeTableProvider,
  UserTableProvider,
} from "../context/table-context";

// A mapping of tableType to its corresponding Provider and configuration
const tableConfigMap = {
  invoices: {
    provider: InvoiceTableProvider,
    label: "Invoices",
    tableType: "invoices", // Ensures the same naming convention
  },
  proposals: {
    provider: ProposalTableProvider,
    label: "Business Proposals",
    tableType: "proposals",
  },
  employees: {
    provider: EmployeeTableProvider,
    label: "Employees",
    tableType: "employees",
  },
  users: {
    provider: UserTableProvider,
    label: "Users",
    tableType: "users",
  },
  customers: {
    provider: CustomerTableProvider,
    label: "Customers",
    tableType: "customers",
  },
  leads: {
    provider: LeadTableProvider,
    label: "Leads",
    tableType: "leads",
  },
};

const MainTable = () => {
  const { tableType } = useParams(); // Retrieve dynamic part of the URL
  console.log("MainTable component received tableType from URL:", tableType);

  // If the tableType doesn't match the expected ones, show an error message
  const config = tableConfigMap[tableType];
  if (!config) {
    return <div>Invalid table type</div>;
  }

  const { provider: Provider, label, tableType: configTableType } = config;
  console.log("MainTable is using configTableType:", configTableType);

  return (
    <Provider>
      <h2>{label}</h2>
      <Table tableType={configTableType} />
    </Provider>
  );
};

export default MainTable;
