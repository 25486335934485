import React, { createContext, useState, useContext, useEffect } from "react";
import axiosInstance from "../../../axios-config";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from 'jwt-decode'

// Create the UserContext
const UserContext = createContext();

// Custom hook to access the user context
export const useUser = () => useContext(UserContext);

// Helper function to check if the token is expired
const isTokenExpired = (token) => {


  if (!token) return true;

  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000; // current time in seconds

  return decoded.exp < currentTime;
};

// UserProvider component that provides the user state and login/logout logic
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Function to handle login
  const login = async (formData) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        "/api/auth/login",
        formData,
        {
          headers: { "Content-Type": "application/json" },
          
        }
      );

      const userData = response.data.user;
      const token = response.data.token; // Retrieve the token


      // Add token to the user data
      const userWithToken = { ...userData, token };

      setUser(userWithToken); // Store user with token in context
      console.log("Login successful:", response.data);

         // Return success indication
    return true;
    } catch (error) {
      console.error(
        "Login failed:",
        error.response ? error.response.data : error.message
      );
      return false; // Return failure indication
    } finally {
      setLoading(false);
    }
  };

  // Function to handle logout
  const logout = async () => {
    setUser(null); // Clear the user state
    navigate("/login", { replace: true }); // Redirect to login
  };

  // Periodically check token expiration and logout if expired
  useEffect(() => {
    const interval = setInterval(() => {
      if (user && user.token && isTokenExpired(user.token)) {
        logout();
      }
    }, 60000); // Check every 1 minute

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [user, logout]);

  return (
    <UserContext.Provider value={{ user, setUser, loading, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};