import { useInvoiceTableContext, useProposalTableContext } from "../context/table-context";
import { fetchTableData } from "../fetch-table-data";

// Custom hook for search functionality
export const useSearch = (tableType) => {
  console.log("useSearch invoked with tableType:", tableType);
  // Access both contexts outside condition to avoid conditional hook usage
  const invoiceContext = useInvoiceTableContext();
  const proposalContext = useProposalTableContext();

  // Destructure context based on tableType
  const context = tableType === "invoices" ? invoiceContext : proposalContext;

   // Check if context is defined
   if (!context) {
    console.error(`Context for ${tableType} is not defined!`);
    return { state: undefined, handleSearchChange: undefined }; // Return default values
  }

  const { state, dispatch } = context;

  // Handle changes in the date range
  const handleDateChange = (selectedDates) => {
    dispatch({ type: "SET_DATE_RANGE", payload: selectedDates, tableType });
  };

  // Handle changes in the search query
  const handleSearchChange = (query) => {
    dispatch({ type: "SET_SEARCH_QUERY", payload: query,tableType});
  };

  // Handle changes in the status filter
  const handleStatusChange = (status) => {
    dispatch({ type: "SET_STATUS_FILTER", payload: status, tableType });
  };

  // Clear all search fields and reset the data
  const clearFields = async () => {
    dispatch({ type: "CLEAR_FIELDS", tableType });

    try {
      const allData = await fetchTableData(tableType);
      console.log(`Fetched data for ${tableType}:`, allData); // Log fetched data for debugging
      dispatch({ type: "SET_FILTERED_DATA", payload: allData, tableType: tableType });
    } catch (error) {
      console.error(`Error fetching data for ${tableType}:`, error);
    }
  };

  // Log current state for debugging purposes
  console.log(`${tableType} context state:`, state);

  return {
    state,
    handleDateChange,
    handleSearchChange,
    handleStatusChange,
    clearFields,
  };
};