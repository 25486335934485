//useRegisterHandlers custom hook

import { useContext } from "react";
import { RegisterFormContext } from "../context/register-form";
import { handleRegister } from "../create-user";

export const useRegisterHandlers = () => {
  const { state, dispatch } = useContext(RegisterFormContext);

  // Handles input changes for all text, email, and select fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: "SET_FORM_VALUES",
      field: name,
      value: name === "resume" ? e.target.files[0] : value, // Special handling for file inputs
    });
  };

  // Handles changes for role selection specifically
  const handleRoleChange = (e) => {
    const { value } = e.target;
    dispatch({
      type: "SET_SELECTED_ROLE",
      role: value,
    });
  };

  // Function to handle form submission for user or employee registration
  const handleSubmit = (e, formType) => {
    e.preventDefault();
    handleCreateUser(
      formType,
      state,
      dispatch,
      () =>
        alert(
          `${
            formType.charAt(0).toUpperCase() + formType.slice(1)
          } created successfully!`
        ),
      (error) => alert("There was an issue saving. Please try again.")
    );
  };

  // Toggles between user and employee registration forms
  const toggleFormType = (isEmployeeForm) => {
    dispatch({
      type: "TOGGLE_FORM_TYPE",
      isEmployeeForm,
    });
  };

 const handleFileChange = (e) => {
    const { name, files } = e.target;
    dispatch({
      type: "SET_FORM_VALUES",
      field: name,
      value: files[0], // Assuming single file upload
    });
  };
  // Handles clearing all errors
  const clearErrors = () => {
    dispatch({ type: "CLEAR_ERRORS" });
  };
  // Function to close the modal by dispatching an action to toggle modal state
  const closeRegisterModal = () => {
    dispatch({ type: "TOGGLE_MODAL" });
  };
  // Function to handle the form submission
  const handleCreateUser = (e) => {
    e.preventDefault();
    handleRegister();
  };

 

  const handleUserChoice = (choice) => {
    handleRegister();

    // Continue with print or email action after saving
    switch (choice) {
      case "email":
        alert("Email functionality not implemented yet");
        break;
      default:
        alert("Unhandled choice selected. Please try again.");
    }
  };
  return {
    handleRoleChange,
    handleUserChoice,
    closeRegisterModal,
    handleInputChange,
    handleSubmit,
    handleFileChange,
    clearErrors,
    toggleFormType,
  };
};
