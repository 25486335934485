// src/components/Login.js
import React from "react";
import { useUser } from "./context/login-context";
import ReusableForm from "./reusable-form";
import formFieldsConfig from "./json/login-config.json";
import { useNavigate } from "react-router-dom";
import "./login.css";

const Login = () => {
  const { login } = useUser();
  const navigate = useNavigate();

  const handleSubmit = async (formData) => {
    const success = await login(formData);
    if (success) {
      navigate("/admin/dashboard");
    } else {
      alert("Login failed. Please check your credentials.");
    }
  };
  return (
    <div className="container-fluid">
      <div className="row vh-100">
        <div className="col-12">
          <div className="p-0">
            <div className="row d-flex align-items-center">
              <div className="col-md-6 col-xl-6 col-lg-6">
                <div className="row">
                  <div className="col-md-6 mx-auto">
                    <div className="mb-0 border-0">
                      <div className="p-0">
                        <div className="text-center">
                          <div className="mb-4">
                            <button href="#" className="auth-logo">
                              <img
                                src="https://magic-rainbow-app-01.s3.eu-west-1.amazonaws.com/logo/Logo.png"
                                alt="logo-dark"
                                className="mx-auto"
                                height="50"
                              />
                            </button>
                          </div>

                          <div className="auth-title-section mb-3">
                            <h3 className="text-dark fs-20 fw-medium mb-2">
                              Welcome Back
                            </h3>
                            <p className="text-dark text-capitalize fs-14 mb-0">
                              Please enter your details.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="pt-0">
                        <ReusableForm
                          fields={formFieldsConfig.loginFields}
                          onSubmit={handleSubmit}
                        >
                          <button
                            type="submit"
                            className="btn btn-primary"

                          >
                            Login
                          </button>
                        </ReusableForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-6 col-lg-6 p-0 vh-100 d-flex justify-content-center account-page-bg"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
